import React, { JSX, useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import * as Styled from './style';

interface Props {
  isCardLoader?: any;
}

export default function CustomLoader({ isCardLoader }: Props): JSX.Element {
  const { mode } = useContext(ThemeContext);

  return isCardLoader ? (
    <Styled.CustomCardLoaderContainer mode={mode}>
      <svg className="spinner-svg spinner-header" viewBox="0 0 50 50">
        <circle className="svg-spinner-background" cx="25" cy="25" r="20" />
        <circle className="svg-spinner-spin" cx="25" cy="25" r="20"></circle>
      </svg>
    </Styled.CustomCardLoaderContainer>
  ) : (
    <Styled.CustomLoaderContainer mode={mode} className="card-panel">
      <svg className="spinner-svg spinner-header" viewBox="0 0 50 50">
        <circle className="svg-spinner-background" cx="25" cy="25" r="20" />
        <circle className="svg-spinner-spin" cx="25" cy="25" r="20"></circle>
      </svg>
    </Styled.CustomLoaderContainer>
  );
}
