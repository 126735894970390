import React from 'react';
import { OverviewComponentType, ProductOverviewRegistry } from '../../types';
import ValueCreationScorecard from 'containers/OverView/ArchletOverview/Scorecards/ValueCreationScorecard';
import UserBehaviorScorecard from 'containers/UserBehaviorEngagement/RGM/SummaryMetricsRgmApp';

// Define the default scorecards for the Archlet product.
const scorecards: OverviewComponentType[] = [
  () => (
    <ValueCreationScorecard
      title="Value Creation"
      slug="archlet-valuecreation-overview"
    />
  ),
  () => <UserBehaviorScorecard title="User Adoption" />,
];

// No custom overview for this product (or set to null).
const customOverview: OverviewComponentType | null = null;

const archletRegistry: ProductOverviewRegistry = {
  scorecards,
  customOverview,
};

export default archletRegistry;
