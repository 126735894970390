import React from 'react';
import { OverviewComponentType, ProductOverviewRegistry } from '../../types';
import AppPerformanceHOC from 'containers/OverView/B2BOverview/Highlights/AppPerformanceHOC';

const scorecards: OverviewComponentType[] = [];

// Provide a default custom overview.
const customOverview: OverviewComponentType | null = () => (
  <AppPerformanceHOC
    slug="app-performance"
    title="App Performance"
    isOverview={true}
  />
);

const kazandirioRegistry: ProductOverviewRegistry = {
  scorecards,
  customOverview,
};

export default kazandirioRegistry;
