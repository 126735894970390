import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './PowerBI.css';
import { useOverview } from '../../hooks/overview-hook';
import { constants } from '../../constants/Constants';

interface EmbedTokenData {
  embedToken: string;
  accessToken: string;
  expiration: any;
}

// PSD Lite credentials
const REPORT_ID = '9cbfad86-10e8-4228-8b32-daa71c09e4ff';
const WORKSPACE_ID = 'f211da8c-d770-4bc2-b4e6-7346f121109f';

const PowerBIDashboard = () => {
  const [embedToken, setEmbedToken] = useState('');
  const {
    getEmbedTokenData,
    embedTokenData,
  }: { getEmbedTokenData: any; embedTokenData: EmbedTokenData[] } =
    useOverview();

  const powerbiCriteria = {
    workspaceId: WORKSPACE_ID,
    reportId: REPORT_ID,
    serviceName: constants.powerBIServices.psdLite,
  };

  useEffect(() => {
    getEmbedTokenData({
      variables: {
        powerbiCriteria: powerbiCriteria,
      },
    });
  }, [getEmbedTokenData]);

  useEffect(() => {
    if (embedTokenData) {
      setEmbedToken(embedTokenData['embedToken']);
    }
  }, [embedTokenData]);

  return (
    <div className="iframeContainer" data-testid="power-bi-report-container">
      {embedToken && (
        <PowerBIEmbed
          embedConfig={{
            type: 'report',
            id: REPORT_ID,
            embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${REPORT_ID}&groupId=${WORKSPACE_ID}`,
            accessToken: embedToken,
            tokenType: models.TokenType.Embed,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
                pageNavigation: {
                  visible: false,
                },
              },
              layoutType: models.LayoutType.Custom,
              customLayout: {
                displayOption: models.DisplayOption.FitToWidth,
              },
            },
          }}
          eventHandlers={
            new Map([
              [
                'loaded',
                function () {
                  console.log('Report loaded');
                },
              ],
              [
                'rendered',
                function () {
                  console.log('Report rendered');
                },
              ],
              [
                'error',
                function (event) {
                  console.log('Error:', event.detail);
                },
              ],
            ])
          }
          cssClassName={'Embed-container'}
        />
      )}
    </div>
  );
};

export default PowerBIDashboard;
