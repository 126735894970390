import React, { useState, useEffect, useRef, useContext } from 'react';
import { color } from '../../theme/Color';
import PropTypes from 'prop-types';
import * as Styled from './style';
import { Grid, Box, Typography, Stack } from '@mui/material';
import './BasicTabs.css';
import FilterBar from '../FilterBar/FilterBar';
import { screenSizes, constants } from '../../constants/Constants';
import useCustomMediaQuery from '../../utils/useCustomMediaQuery';
import { ThemeContext } from '../../context/ThemeContext';
import { FilterContext } from 'components/FilterBar/FilterProvider';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Styled.TabsDiv
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <div className="tabPanel">{value === index && <Box>{children}</Box>}</div>
    </Styled.TabsDiv>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs({ tabArray, product, model, sector }) {
  const [value, setValue] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const { setSelectedTab, ytdCheckbox, startUTCDate } =
    useContext(FilterContext);

  const isMobile = useCustomMediaQuery(screenSizes.mobile);
  const isTablet = useCustomMediaQuery(screenSizes.tablet);
  const { mode } = useContext(ThemeContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedTab(tabArray[newValue]);
  };

  const formatDateToMonthYear = (utcDate) => {
    const date = new Date(utcDate);
    return date.toLocaleString('en-US', { year: 'numeric', month: 'long' });
  };

  const prevProductRef = useRef(null);
  useEffect(() => {
    const options = tabArray.map((tab, index) => ({
      value: index,
      label: tab.label,
    }));
    if (product) {
      if (
        (isMobile || isTablet) &&
        (!selectedOption || selectedOption.value >= options.length)
      ) {
        setSelectedOption(options[0]);
      } else if (
        product !== prevProductRef.current?.product ||
        model !== prevProductRef.current?.model ||
        (product?.productId === prevProductRef.current?.product?.productId &&
          prevProductRef.current?.sector !== undefined &&
          sector !== prevProductRef.current?.sector)
      ) {
        setValue(0);
        setSelectedTab(tabArray[0]);
      }
    }
    prevProductRef.current = { product, model };
  }, [tabArray, product, sector, selectedOption, model]);

  return (
    <Styled.GridContainer
      container
      flexDirection={'column'}
      data-accessible={true}
      data-testid="grid-container"
      mode={mode}
    >
      <Stack
        direction={'column'}
        className="content"
        sx={{
          overflow: 'hidden',
          background: mode === 'light' ? color._F3F7FD : color._131313,
        }}
        data-accessible={true}
        data-testid="stack-container"
      >
        {!isMobile && (
          <>
            <Stack id="content-header" direction={'row'}>
              <Stack className="contentTitle" id="program-title" xs={4}>
                <span data-html2canvas-ignore>
                  {product?.programId === constants?.programs?.HCT
                    ? product?.programName
                    : product?.programFullName}
                </span>
                {!isTablet && product && (
                  <Typography
                    variant="span"
                    className="content-subtitle margin-top-30"
                    sx={{
                      color: mode === 'light' ? color._131313 : color._F3F7FD,
                    }}
                  >
                    {product?.productId === constants?.products?.GuidedSelling
                      ? 'GS ' + model?.label
                      : product?.productName}
                  </Typography>
                )}
              </Stack>
              <Stack className="contentTitle" id="pdf-product-title" xs={4}>
                {product && (
                  <Typography
                    variant="span"
                    className="content-subtitle"
                    sx={{
                      color: mode === 'light' ? color._131313 : color._F3F7FD,
                    }}
                  >
                    {product?.productId === constants?.products?.GuidedSelling
                      ? 'GS ' + model?.label
                      : product?.productName}{' '}
                    - {tabArray[value]?.label}
                  </Typography>
                )}
              </Stack>
              <Stack xs={8}>
                <div id="filters">
                  <FilterBar hideDropdowns={false} />
                </div>
                <div id="yearValue">
                  <div className="selectedDate">
                    <Typography
                      variant="span"
                      className="content-subtitle"
                      sx={{
                        color: mode === 'light' ? color._131313 : color._F3F7FD,
                      }}
                    >
                      {ytdCheckbox
                        ? new Date(startUTCDate).getUTCFullYear()
                        : formatDateToMonthYear(startUTCDate)}
                    </Typography>
                  </div>
                </div>
              </Stack>
            </Stack>
            {isTablet && (
              <Stack
                direction={'row'}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Grid
                  className="subHeaderContainer"
                  item
                  data-testid="basic-tablet"
                >
                  {product && (
                    <Typography
                      variant="span"
                      className={`subHeader basic-${mode}`}
                    >
                      {product?.productId === constants?.products?.GuidedSelling
                        ? 'GS ' + model?.label
                        : product?.productName}
                    </Typography>
                  )}
                </Grid>
              </Stack>
            )}
          </>
        )}
        <Grid item>
          {isMobile && (
            <Stack
              id="content-title"
              className={`basic-${mode}`}
              style={{ fontSize: '24px' }}
            >
              {product?.programId === constants?.programs?.HCT
                ? product?.programName
                : product?.programFullName}
            </Stack>
          )}
        </Grid>
        <Grid item>
          <Box>
            {isMobile ? (
              <Stack data-testid="basic-tab-mobile">
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {product && (
                      <Typography
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '18px',
                          fontStyle: 'normal',
                          fontWeight: '700',
                          color: '#D0D0D0',
                        }}
                        className={`basic-${mode}`}
                      >
                        {typeof product === 'object'
                          ? product?.productId ===
                            constants?.products?.GuidedSelling
                            ? 'GS ' + model?.label
                            : product?.productName
                          : product}
                      </Typography>
                    )}
                  </Stack>
                  <FilterBar hideDropdowns={false} />
                </Stack>
                <Grid item>
                  <Styled.TabContainer
                    value={value}
                    onChange={handleChange}
                    inputProps={{
                      autoCapitalize: 'none',
                    }}
                    variant="scrollable"
                    scrollButtons={false}
                    mode={mode}
                  >
                    {tabArray?.map((tab) => (
                      <Styled.TabItems
                        key={tab.id}
                        label={tab.label}
                        mode={mode}
                      />
                    ))}
                  </Styled.TabContainer>
                </Grid>
              </Stack>
            ) : (
              <Stack data-testid="basic-tab-tablet">
                <Styled.TabContainer
                  id="tab-container"
                  value={value}
                  onChange={handleChange}
                  inputProps={{
                    autoCapitalize: 'none',
                  }}
                  wrapped={true}
                  variant="scrollable"
                  scrollButtons={false}
                  mode={mode}
                  data-html2canvas-ignore
                >
                  {tabArray?.map((tab) => (
                    <Styled.TabItems
                      key={tab.id}
                      label={tab.label}
                      mode={mode}
                    />
                  ))}
                </Styled.TabContainer>
              </Stack>
            )}
            {tabArray?.map((tab, index) => (
              <TabPanel key={index} value={value} index={index}>
                <tab.component />
              </TabPanel>
            ))}
          </Box>
        </Grid>
      </Stack>
    </Styled.GridContainer>
  );
}
