import { gql } from '@apollo/client';

const getScorecardOverview = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getOperationalMetricsOverview(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        activeStore
        totalStore
        activeStorePercentage
        shareOfDgtOrderPercentage
        avgLoginPerActiveStore
        incrementalNetRevenue
        activeStoreIncrementalNetRevenue
      }
      errors
      responseCode
      responseMessage
    }
    getTotalOrderProcessed(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        totalOrderProcessedForLast24Hours
      }
      errors
      responseCode
      responseMessage
    }
    getTotalRevenueGenerated(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        totalRevenueGeneratedForLast24Hours
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getB2BScorecardOverview = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getB2BOverviewScorecard(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        loginStoresPercentage
        loginStore
        totalStore
        registeredStores
        planRegisteredStores
        incrementalNetRevenue
        planIncrementalNetRevenue
        digitalRevenueProcessed
        productivityActual
        planProductivityActual
        digitalOfTotalRevenuePercentage
        customerRedeemingPoints
        openIncident
        availability
        customerEngagementChurn
        customerOrderingChurn
        customerLoyaltyChurn
        customerEngagementChurnPeriodComparison
        customerOrderingChurnPeriodComparison
        customerLoyaltyChurnPeriodComparison
        openIncidentPeriodComparison
        digitalRevenueProcessedPeriodComparison
        digitalOfTotalRevenuePercentagePeriodComparison
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getB2BSteerCoScorecardOverview = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getSteerCoReportingSummary(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        registeredStores
        registeredStoresCARDelta
        registeredStoresWeekDelta
        grossSales
        grossSalesDelta
        digitalRevenueTotalOrdersPercentage
        digitalRevenueTotalOrdersPercentageDelta
        storeLoginRatePercentage
        storeLoginRatePercentageDelta
        storeLoginFrequencyPercentage
        storeLoginFrequencyPercentageDelta
        digitalOrderChurnPercentage
        digitalOrderChurnPercentageDelta
        b2bOrderFillRatePercentage
        b2bOrderFillRatePercentageDelta
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getB2BSteerCoMarketComparisonView = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getSteerCoReportingMarketComparison(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        marketName
        marketComparisonMetrics {
          registeredStoresActual
          registeredStoresBaseline
          grossSales
          digitalRevenueTotalOrdersPercentage
          storeLoginRatePercentage
          storeLoginFrequencyPercentage
          digitalOrderChurnPercentage
          b2bOrderFillRatePercentage
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getOperationalMetricsInOverview = gql`
  query (
    $operationalMetricCriteria: OperationalMetricesRequestDTOInput!
    $digitalOrdersChartsMetricCriteria: OperationalMetricesRequestDTOInput!
  ) {
    getStoreVersusPlan(operationalMetricCriteria: $operationalMetricCriteria) {
      data {
        totalRegisteredStore
        activeStore
        registeredStoreCurrentMonth
        engagedStore
        planRegisteredStore
        planActiveStores
      }
      errors
      responseCode
      responseMessage
    }

    getStoreReachingTarget(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        selectedPeriod {
          dateTime
          summaryMetricValue
        }
        monthlyAvgYearToDate {
          dateTime
          summaryMetricValue
        }
        yearToDate {
          dateTime
          summaryMetricValue
        }
      }
      responseCode
      responseMessage
    }

    getDigitalOrdersSummary(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        totalDigitalOrder
        totalNonDigitalOrder
        activeOrderingStore
      }
      errors
      responseCode
      responseMessage
    }

    getDigitalTotalOrdersCharts(
      operationalMetricCriteria: $digitalOrdersChartsMetricCriteria
    ) {
      data {
        digitalNetSale
        dateTime
        totalDigitalOrder
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getUserBehaviourAndEngagement = gql`
  query ($overviewMetricCriteria: OverviewMetricesRequestDTOInput!) {
    getUserAdoptionScorecard(overviewMetricCriteria: $overviewMetricCriteria) {
      data {
        avgLoginPerActiveStore
        avgTimeInPlatform
        uniquePageViews
        activeUser
        newUser
        totalUser
        userFrequency
        uniqueSessions
        adoptionRatePercentage
        adoptionRatePercentageDelta
        valuableActionsTaken
        avgTimeInPlatformUnit
        avgLoginPerActiveStoreDelta
        uniquePageViewsDelta
        avgTimeInPlatformDelta
        baselinesTemplatesDownloaded
      }
      errors
      responseCode
      responseMessage
    }
    getTopEntryPage(overviewMetricCriteria: $overviewMetricCriteria) {
      data {
        pageName
        visitedPercentage
      }
      errors
      responseCode
      responseMessage
    }
    getRageClick(overviewMetricCriteria: $overviewMetricCriteria) {
      data {
        rageName
        ragePercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAfhRecommendationsPageData = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getRecommendationsPage(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        pageName
        recommendationPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getPromoAiValueCreationTrendData = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getRGMValueCreationScorecard(psdCriteria: $psdCriteria) {
      data {
        marketsSupported
        averageTechnicalForecastAccuracy
        technicalForecastAccuracyDelta
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getApplicationPeformanceMetricsInOverview = gql`
  query (
    $applicationMetricCriteria: ApplicationMetricsRequestDTOInput!
    $incidentMetricCriteria: ApplicationMetricsRequestDTOInput!
  ) {
    getAppPerformanceOverviewScorecard(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        appCrashes
        appCrashesDelta
        networkLatency
        networkLatencyDelta
        networkErrors
        networkErrorDelta
      }
      errors
      responseCode
      responseMessage
    }

    getIncidentTrend(applicationMetricCriteria: $incidentMetricCriteria) {
      data {
        dateTime
        incidentCount
        resolvedWithInSla
        responseWithInSla
      }
      errors
      responseCode
      responseMessage
    }

    getIncidentMonitoringOverview(
      applicationMetricCriteria: $incidentMetricCriteria
    ) {
      data {
        openIncident
        openIncidentDelta
        incidentCount
        incidentCountDelta
        responseTime
        responseTimeDelta
        responseTimeUnit
        resolutionTime
        resolutionTimeDelta
        resolutionTimeUnit
      }
      errors
      responseCode
      responseMessage
    }

    getIncidentMonitoringPriorityLevelGraph(
      applicationMetricCriteria: $incidentMetricCriteria
    ) {
      data {
        dateTime
        sumOfIncidentCount
        priorityDetail {
          priority
          incidentCount
        }
      }
      errors
      responseCode
      responseMessage
    }

    getIncidentMonitoringOpenAndClose(
      applicationMetricCriteria: $incidentMetricCriteria
    ) {
      data {
        openIncident
        closedIncident
      }
      errors
      responseCode
      responseMessage
    }

    getIncidentMonitoringSlaAnalysis(
      applicationMetricCriteria: $incidentMetricCriteria
    ) {
      data {
        slaType
        incidentCount
        incidentPercentage
        incidentOverallAvgPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGSNBARecommendation = gql`
  query ($nbaCriteria: NBAMetricsRequestDTOInput!) {
    getGSNBARecommendation(nbaCriteria: $nbaCriteria) {
      data {
        storesInMarketWithNBA
        nbaAccepted
        nbaDisplayed
        storeAdoptionPercentage
        nbaAdoptionPercentage
        nbaTrend {
          dateTime
          storesInMarketWithNBA
          nbaAccepted
          nbaDisplayed
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getNBAUseCaseNames = gql`
  query {
    getNBAUseCaseNames {
      data {
        marketId
        useCaseToDisplayNameMap
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGSNBASalesGrowthTrend = gql`
  query ($nbaCriteria: NBAMetricsRequestDTOInput!) {
    getGSNBASalesGrowthTrend(nbaCriteria: $nbaCriteria) {
      data {
        dateTime
        growthRatebyCategory {
          category
          growthRate
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGSNBAScoreCard = gql`
  query ($nbaCriteria: NBAMetricsRequestDTOInput!) {
    getGSNBAScoreCard(nbaCriteria: $nbaCriteria) {
      data {
        storesInMarketWithNBA
        nbaAccepted
        nbaDisplayed
        storeAdoptionPercentage
        nbaAdoptionPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGSNBAAdoptionRateTrend = gql`
  query ($nbaCriteria: NBAMetricsRequestDTOInput!) {
    getGSNBAAdoptionRateTrend(nbaCriteria: $nbaCriteria) {
      data {
        nbaUseCases
        values {
          dateTime
          adoptionRate
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGSNBAStoreAdoptionRateTrend = gql`
  query ($nbaCriteria: NBAMetricsRequestDTOInput!) {
    getGSNBAStoreAdoptionRateTrend(nbaCriteria: $nbaCriteria) {
      data {
        nbaUseCases
        values {
          dateTime
          adoptionRate
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGlobalFilterComparionRes = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getGlobalFilterComparionRes(psdCriteria: $psdCriteria) {
      data {
        productId
        productName
        metrics {
          metricId
          metricDisplayName
          metricDefinition
          metricUnit
          xaxisLabels {
            labelName
            labelValue
            stacks {
              stackName
              stackValue
            }
          }
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

export {
  getScorecardOverview,
  getB2BScorecardOverview,
  getB2BSteerCoScorecardOverview,
  getB2BSteerCoMarketComparisonView,
  getOperationalMetricsInOverview,
  getUserBehaviourAndEngagement,
  getApplicationPeformanceMetricsInOverview,
  getGSNBARecommendation,
  getNBAUseCaseNames,
  getGSNBASalesGrowthTrend,
  getGSNBAScoreCard,
  getGSNBAAdoptionRateTrend,
  getGSNBAStoreAdoptionRateTrend,
  getAfhRecommendationsPageData,
  getPromoAiValueCreationTrendData,
  getGlobalFilterComparionRes,
};
