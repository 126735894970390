import React from 'react';
import { OverviewComponentType, ProductOverviewRegistry } from '../../types';
import UserBehaviorScorecard from 'containers/UserBehaviorEngagement/RGM/SummaryMetricsRgmApp';
import ValueCreationScoreCard from 'containers/ValueCreationMetrics/RGMAppValueCreation/ScoreCard';

const scorecards: OverviewComponentType[] = [
  () => <ValueCreationScoreCard title={'Value Creation'} />,
  () => <UserBehaviorScorecard title="User Adoption" />,
];

const customOverview: OverviewComponentType | null = null;

const appRegistry: ProductOverviewRegistry = {
  scorecards,
  customOverview,
};

export default appRegistry;
