import styled from 'styled-components';

export const B2BOverviewStyles = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    gap: 16px;

    @media (min-width: 768px) {
      padding-top: 24px;
      gap: 24px;
    }
    & .kpi-container-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  `,
  // Define a wrapper to control layout without modifying existing components
  LayoutWrapper: styled.div`
    display: flex;
    gap: 16px;

    // Stack in a column layout for mobile screens
    @media (max-width: 600px) {
      flex-direction: column;
      gap: 16px;
    }

    // Stack in a column layout for tablets
    @media (min-width: 601px) and (max-width: 1024px) {
      flex-direction: column;
      gap: 16px;
    }

    // Specific styling for iPad Mini
    @media (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px) {
      flex-direction: column;
      gap: 16px;
    }

    // Specific styling for iPad Pro
    @media (min-width: 1024px) and (max-width: 1366px) and (device-width: 1024px) and (device-height: 1366px) {
      flex-direction: column;
      gap: 16px;
    }

    // Specific styling for iPad Air
    @media (min-width: 820px) and (max-width: 1180px) {
      flex-direction: column;
      gap: 16px;
    }

    // For desktop screens, switch to a row layout
    @media (min-width: 1200px) {
      flex-direction: row;
      gap: 16px;
    }

    & .loaderContainer {
      text-align: center;
    }
  `,

  KpiWrapper: styled.div`
    flex: 1; // Ensures that KpiGrid takes equal width as chart on desktop
    display: flex;
    flex-direction: column;
    gap: 24px;
    & > :first-child {
      padding: 24px 0px 24px 24px !important; // Adjust padding value as needed
    }
  `,

  ChartWrapper: styled.div`
    flex: 1; // Ensures that chart takes equal width as KpiGrid on desktop
  `,

  CharContainerHeight: styled.div`
    max-height: 202px;
    @media (max-width: 1280px) {
      max-height: 222px;
    }
  `,
};

export const B2BSteerCoStyles = {
  HeaderRow: styled.div`
    display: flex;
    height: 59px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;

    @media (max-width: 767px) {
      /* Applies for mobile */
      flex-direction: column;
      align-items: stretch; /* Stretch items full width */
      height: 100px;
    }
  `,
  SwitcherContainer: styled.div`
    display: flex;
    height: var(--Spacing-spacing-32, 32px);
    align-items: center;
    border-radius: 8px;

    @media (max-width: 767px) {
      width: 100%;
      justify-content: center;
    }
  `,
  WeekPickerContainer: styled.div`
    display: flex;
    height: 59px;
    max-width: 400px;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 767px) {
      width: 100%; /* Stretch week picker full width */
      align-items: center;
      margin-top: 10px; /* Add spacing */
      position: relative;
      min-height: 150px;
    }
  `,
};
