import React, {
  useState,
  CSSProperties,
  createContext,
  useMemo,
  useEffect,
} from 'react';
import {
  StyledCard,
  HeaderWrapper,
  LeftHeaderWrapper,
  CardHeader,
  IconsWrapper,
  IconWrapper,
  CardContent,
  ChildWrapper,
  LoaderWrapper,
  AccordionIconWrapper,
  AccordionHeaderWrapper,
  AccordionCardContentOpen,
  AccordionCardContentClosed,
  InfoIconWrapper,
} from './style';
import {
  MdExpandMore,
  MdExpandLess,
  MdDragIndicator,
  MdInfoOutline,
} from 'react-icons/md';
import Loader from 'components/Loader/Loader';
import { useAppContext } from '../../../context/AppContext';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {},
});

interface CardContainerProps {
  title: string;
  children: React.ReactNode;
  kpiDefinitionDrawer?: React.ReactNode;
  isAccordion?: boolean;
  isDraggable?: boolean;
  isLoading?: boolean;
  slug?: string;
  dataDefinitions?: Record<string, any>;
  showInfoIcon?: boolean;
}

const CardContainer: React.FC<CardContainerProps> = ({
  title,
  slug,
  children,
  dataDefinitions = null,
  isAccordion = true,
  isDraggable = false,
  isLoading = false,
  showInfoIcon = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [wasOpen, setWasOpen] = useState<boolean>(true); // Track previous open state

  // Pass `disabled: !isDraggable` to disable dragging when isDraggable is false
  const {
    active,
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: slug, disabled: !isDraggable });

  const {
    kpiDrawer: { toggleDrawer, isDrawerShown, setKpiDrawerProps },
  } = useAppContext();

  useEffect(() => {
    if (active !== null) {
      setWasOpen(isOpen); // Save the current state before closing
      setTimeout(() => setIsOpen(false), 400);
    } else {
      setIsOpen(wasOpen); // Restore the previous state
    }
  }, [active]);

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  );

  const style: CSSProperties = {
    opacity: isDragging && isDraggable ? 0.5 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const handleHeaderClick = (event: React.MouseEvent) => {
    // Check if the event originated from an element with the data-dnd-handle attribute
    if ((event.target as HTMLElement).closest('[data-dnd-handle]')) {
      return; // Do nothing if the drag handle was clicked
    }

    if (isAccordion) {
      setIsOpen(!isOpen);
    }
  };
  const HeaderComponent = isAccordion ? AccordionHeaderWrapper : HeaderWrapper;
  const ContentComponent = isAccordion
    ? isOpen
      ? AccordionCardContentOpen
      : AccordionCardContentClosed
    : CardContent;

  const handleInfoIconClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent triggering the accordion toggle
    if (!isDrawerShown) {
      setKpiDrawerProps({
        title,
        sections: dataDefinitions.sections || [],
        subHeading: dataDefinitions.subHeading || '',
      });
    }

    toggleDrawer();
  };

  return (
    <SortableItemContext.Provider value={context}>
      <StyledCard ref={setNodeRef} style={style}>
        <HeaderComponent onClick={handleHeaderClick}>
          <LeftHeaderWrapper>
            {isDraggable && (
              <IconWrapper
                {...attributes}
                {...listeners}
                ref={setActivatorNodeRef}
                data-dnd-handle
                onMouseDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
              >
                <MdDragIndicator />
              </IconWrapper>
            )}
            <CardHeader data-testid="card-header">{title}</CardHeader>
          </LeftHeaderWrapper>
          <IconsWrapper>
            {showInfoIcon && dataDefinitions && (
              <IconWrapper
                onClick={handleInfoIconClick}
                role="button"
                data-html2canvas-ignore
              >
                <InfoIconWrapper>
                  <MdInfoOutline />
                </InfoIconWrapper>
              </IconWrapper>
            )}
            {isAccordion && (
              <AccordionIconWrapper>
                {isOpen ? <MdExpandLess /> : <MdExpandMore />}
              </AccordionIconWrapper>
            )}
          </IconsWrapper>
        </HeaderComponent>
        <ContentComponent data-testid="card-content">
          {isLoading ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            React.Children.map(children, (child) => (
              <ChildWrapper>{child}</ChildWrapper>
            ))
          )}
        </ContentComponent>
      </StyledCard>
    </SortableItemContext.Provider>
  );
};

export default CardContainer;
