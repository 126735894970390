import {
  formatValueWithMiddlewares,
  checkForNA,
  applySuffix,
  createKpiTileItem,
  appendPercentage,
} from '../../../../utils/helper';

export default {
  type: 'KpiTile',
  handler: (data) => {
    const { scenariosEdited, scenariosEditedDelta } = data;

    return createKpiTileItem(
      'Scenarios Edited',
      formatValueWithMiddlewares(scenariosEdited, [checkForNA, applySuffix]),
      {
        isPositiveValue: scenariosEditedDelta > 0,
        text: formatValueWithMiddlewares(scenariosEditedDelta, [
          checkForNA,
          appendPercentage,
        ]).toString(),
        sideLabel: '',
        showArrow: scenariosEditedDelta !== 0,
      }
    );
  },
};
