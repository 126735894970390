import React, { useState, useEffect, useContext } from 'react';
import SubMenu from './SubMenu';
import * as Styled from './style';
import { useLocation } from 'react-router-dom';
import { getActiveNavigation } from '../../utils/helperFunctions';
import List from '@mui/material/List';
import { ThemeContext } from '../../context/ThemeContext';

const Sidebar = ({ navigation, sidebar, setSidebar }) => {
  const location = useLocation();
  const { mode } = useContext(ThemeContext);
  const activeNavigation = getActiveNavigation(navigation, location);
  const [activeNav, setActiveNav] = useState(0);
  useEffect(() => {
    setActiveNav(activeNavigation.navIndex);
  }, [activeNavigation.navIndex]);
  return (
    <>
      <Styled.SidebarNav
        sidebar={sidebar}
        data-accessible={true}
        data-testid="sidebar"
        accessibilityLabel="sidebar"
        mode={mode}
        id="sidebar"
      >
        <Styled.SidebarWrap>
          <List
            sx={{ width: '100%', maxWidth: 360 }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {navigation &&
              navigation
                .filter((element) => {
                  return !element.hide;
                })
                .map((item, index) => {
                  return (
                    <SubMenu
                      item={item}
                      key={index}
                      index={index}
                      sidebar={sidebar}
                      setSidebar={setSidebar}
                      activeNav={activeNav}
                      setActiveNav={setActiveNav}
                    />
                  );
                })}
          </List>
        </Styled.SidebarWrap>
      </Styled.SidebarNav>
      <Styled.Overlay
        onClick={() => setSidebar(false)}
        sidebar={sidebar}
        data-accessible={true}
        data-testid="sidebar-outer-container"
        accessibilityLabel="sidebar-outer-container"
      ></Styled.Overlay>
    </>
  );
};

export default Sidebar;
