import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  from,
  Observable,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

let apolloClient = null;

export function getApolloClient() {
  if (!apolloClient) {
    throw new Error('ApolloClient has not been initialized yet.');
  }
  return apolloClient;
}

class RequestTracker {
  constructor() {
    this.activeRequests = new Set(); // Track request IDs instead of a counter
    this.requestId = 0;
    this.isRequestInProgress = false;
    this.completionCallbacks = new Set();
    this.statusCallbacks = new Set();
  }

  generateRequestId() {
    return `request_${++this.requestId}`;
  }

  addRequest() {
    const requestId = this.generateRequestId();
    this.activeRequests.add(requestId);
    this.updateStatus();
    return requestId;
  }

  removeRequest(requestId) {
    this.activeRequests.delete(requestId);
    this.updateStatus();
  }

  updateStatus() {
    const previousStatus = this.isRequestInProgress;
    this.isRequestInProgress = this.activeRequests.size > 0;

    // Notify status subscribers
    this.statusCallbacks.forEach((callback) =>
      callback({
        isLoading: this.isRequestInProgress,
        activeRequestCount: this.activeRequests.size,
      })
    );

    // Notify completion subscribers only when transitioning from active to complete
    if (previousStatus && !this.isRequestInProgress) {
      this.completionCallbacks.forEach((callback) => callback());
    }
  }

  subscribeToStatus(callback) {
    this.statusCallbacks.add(callback);
    return () => this.statusCallbacks.delete(callback);
  }

  subscribeToCompletion(callback) {
    this.completionCallbacks.add(callback);
    return () => this.completionCallbacks.delete(callback);
  }

  getStatus() {
    return {
      isLoading: this.isRequestInProgress,
      activeRequestCount: this.activeRequests.size,
    };
  }
}

const requestTracker = new RequestTracker();

export const subscribeToRequestStatus =
  requestTracker.subscribeToStatus.bind(requestTracker);
export const subscribeToCompletion =
  requestTracker.subscribeToCompletion.bind(requestTracker);
export const getRequestStatus = requestTracker.getStatus.bind(requestTracker);
const createApolloClient = (oktaAuth) => {
  const token = oktaAuth.getAccessToken();

  const primaryHttpLink = new HttpLink({
    uri: process.env.REACT_APP_CAF_URI || window.REACT_APP_CAF_URI,
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const trackingLink = new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
      const requestId = requestTracker.addRequest();

      const subscription = forward(operation).subscribe({
        next: (result) => {
          observer.next(result);
        },
        error: (error) => {
          requestTracker.removeRequest(requestId);
          observer.error(error);
        },
        complete: () => {
          requestTracker.removeRequest(requestId);
          observer.complete();
        },
      });

      return () => {
        if (subscription) {
          subscription.unsubscribe();
          requestTracker.removeRequest(requestId);
        }
      };
    });
  });

  const opNameLink = new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
      oktaAuth.tokenManager
        .get('accessToken')
        .then((accessToken) => {
          const token = accessToken?.accessToken;
          operation.setContext(({ headers }) => ({
            headers: {
              ...headers,
              authorization: token ? `Bearer ${token}` : '',
            },
          }));

          const subscription = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });

          return () => {
            if (subscription) {
              subscription.unsubscribe();
            }
          };
        })
        .catch((err) => {
          console.error('Error getting access token:', err);
          observer.error(err);
        });
    });
  });

  apolloClient = new ApolloClient({
    link: from([errorLink, trackingLink, opNameLink, primaryHttpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first', // or 'cache-and-network'
      },
    },
  });

  return apolloClient;
};

export { createApolloClient };
