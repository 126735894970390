import styled from 'styled-components';
import { color } from '../theme/Color';

export const ChildDev = styled.div`
  margin-left: 0px;
  padding-top: 64px;
  // min-height: 100vh;
  background-color: ${({ mode }) =>
    mode === 'light' ? color._F3F7FD : color._131313};
  @media only screen and (min-width: 600px) {
    /* Adjust the breakpoint as needed */
    margin-left: 70px;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
`;
