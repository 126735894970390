import styled, { createGlobalStyle } from 'styled-components';
import { color } from '../../theme/Color';

export const FilterBarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & .filter-button {
    color: #fff;
    border-radius: 24px;
    background: #0054ac;
    text-transform: none;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    padding-left: 16px;
    padding-right: 16px;
    height: 32px;
  }
`;

export const GlobalStyle = createGlobalStyle`
  .react-datepicker__input-container {
    input {
      background-color: ${({ mode }) =>
        mode === 'light'
          ? `${color._FBFCFE} !important`
          : `${color._272727} !important`};
      color: ${({ mode }) =>
        mode === 'light' ? '#000 !important' : '#fff !important'};

      &[readonly] {
        border-color: rgba(118, 118, 118, 0.3) !important;
        color: darkgray !important;
      }
    }
  }
  .react-datepicker {
    background-color: ${({ mode }) =>
      mode === 'light'
        ? `${color._FBFCFE} !important`
        : `${color._272727} !important`};
    color: ${({ mode }) => (mode === 'light' ? '#000' : '#fff')};
    border: ${({ mode }) =>
      mode === 'light'
        ? '1px solid #ccc !important'
        : '1px solid #3a3a3a !important'};
  }
  .react-datepicker__header--custom {

    background-color: ${({ mode }) =>
      mode === 'light'
        ? `${color._FBFCFE} !important`
        : 'transparent !important'};
    border-bottom: ${({ mode }) =>
      mode === 'light' ? '1px solid #ccc !important' : 'none !important'};
  }
  .react-datepicker__year--container {
    background-color: ${({ mode }) =>
      mode === 'light'
        ? `${color._FBFCFE} !important`
        : `${color._131216} !important`};
    border: ${({ mode }) =>
      mode === 'light' ? '1px solid #ccc !important' : 'none !important'};
    color: ${({ mode }) => (mode === 'light' ? '#000' : '#fff')};
  }
  .react-datepicker__month-container {
    background-color: ${({ mode }) =>
      mode === 'light'
        ? `${color._FBFCFE} !important`
        : `${color._131216} !important`};
    border: ${({ mode }) =>
      mode === 'light' ? '1px solid #ccc !important' : 'none !important'};
  }

  .react-datepicker__month-text {
    color: ${({ mode }) => (mode === 'light' ? '#000 !important' : '#fff !important')};
  }
  .react-datepicker__year-text {
    color: ${({ mode }) => (mode === 'light' ? '#000 !important' : '#fff !important')};
  }
  .react-datepicker__year-text--disabled {
    color: ${({ mode }) =>
      mode === 'light' ? `${color._616161} !important` : 'darkgray !important'};
  }
  .react-datepicker__month-text--disabled {
    color: ${({ mode }) =>
      mode === 'light' ? `${color._616161} !important` : 'darkgray !important'};
  }
      .react-datepicker__day{
    color: ${({ mode }) => (mode === 'light' ? '#000 !important' : '#efefef !important')};
    font-family: Inter;
    font-style: normal;
    }

    .react-datepicker__current-month {
     color: ${({ mode }) => (mode === 'light' ? '#000 !important' : '#fff !important')};
       font-family: Inter;
  font-style: normal;
  font-weight: 400;
}

.react-datepicker__day-names{
  color: ${({ mode }) => (mode === 'light' ? '#000 !important' : '#efefef !important')};
       font-family: Inter;
}

       .react-datepicker__day-name{
         color: ${({ mode }) => (mode === 'light' ? '#000 !important' : '#efefef !important')};
       }

       .react-datepicker__weekPicker{
       margin : 0px !important;}


.react-datepicker__day-names,
.react-datepicker__week {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr) !important; /* Ensures equal spacing */
  text-align: center !important;

   /* Styling for tablets */
    @media (min-width: 768px) and (max-width: 820px) {
   display: flex !important;
    flex-wrap: nowrap !important; /* Prevent vertical stacking */
    justify-content: space-between !important; /* Even spacing */
    }

  /* For mobile devices */
  @media (max-width: 767px) {
    display: flex !important;
    flex-wrap: nowrap !important;
    justify-content: space-between !important;
    font-size: 12px !important; /* Adjust font size for better readability */
  }
}

#b2b-week-picker .react-datepicker-popper {
 z-index: 10000 !important; /* Higher than the overlay */
@media (max-width: 768px) {
    left: 15% !important; /* Centers it relative to the label */
    transform: translateX(-30px) translateY(20px)
    width: 90% !important; /* Avoids unnecessary stretching */
    z-index: 9999 !important; /* Ensures it stays on top */
  }
}

#b2b-week-picker .react-datepicker__month-container {
  @media (max-width: 767px) {
    width: 100% !important; 
    margin: 0 auto !important;
  }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
 background-color: transparent !important; /* Remove background color */
    color: inherit !important; /* Use default text color */
}

.react-datepicker__day:hover,
.react-datepicker__year-dropdown {
    background-color: ${({ mode }) =>
      mode === 'light' ? `${color._DFEAF9} !important` : `darkgray !important`};
  border-radius: 0 !important; /* Remove rounded corners */
align-items: center !important;
  justify-content: center !important;
  display: flex !important;
 line-height: 1 !important; 
}
`;

export const DatePickerContainer = styled.div`
  position: relative;

  & .CalenderPlacement {
    float: right;
    position: relative;
    margin-top: -23px;
    font-size: 15px;
    background: ${color._FBFCFE};
    margin-right: 5px;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 55%;
  right: 8px;
  transform: translateY(-50%);
  @media (min-width: 300px) and (max-width: 600px) {
    right: 230px;
  }
`;

export const WeeklyIconContainer = styled.div`
  position: absolute;
  top: 40%;
  right: 8px;
  color: ${({ mode }) =>
    mode === 'light'
      ? `${color._616161} !important`
      : `${color._efefef} !important`};
  @media (min-width: 300px) and (max-width: 600px) {
    right: 230px;
  }

  @media (max-width: 768px) {
    right: 10px;
  }
`;

export const DatePickerLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  padding: 8px 12px;
  background: ${({ mode }) =>
    mode === 'light' ? '#fff !important' : '#272727 !important'};
  cursor: pointer;
  height: 14px;
  min-width: 200px;
  color: ${({ mode }) =>
    mode === 'light' ? '#000 !important' : '#fff !important'};

  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 768px) {
    width: 320px !important;
    min-width: unset !important;
  }

  @media (max-width: 376px) {
    width: 280px !important;
  }
`;

export const WeekPickerLabel = styled.div`
  margin-bottom: 4px;
  color: ${({ mode }) =>
    mode === 'light'
      ? `${color._616161} !important`
      : `${color._efefef} !important`};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;
