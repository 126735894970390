import React, { useContext, useEffect } from 'react';
import CardContainer from 'components/redesign/CardContainer';
import KpiContainer from 'components/redesign/KpiContainer';
import { useOverview } from 'hooks/overview-hook';
import { FilterContext } from 'components/FilterBar/FilterProvider';
import { useMetricsCriteria } from 'hooks/useMetricsCriteria';
import {
  processMetricsData,
  transformMetricsToDefinitions,
} from './helper/valueCompare';
import StackedMutiColumnBarChart from 'components/StackedMultiColumnBarChart';
import NoDataFound from 'components/ErrorHandling/NoDataFound';
import GridContainer from 'components/redesign/GridContainer';
import { ThemeContext } from '../../context/ThemeContext';
import * as Styled from './style';
import { screenSizes, constants } from 'constants/Constants';
import useCustomMediaQuery from 'utils/useCustomMediaQuery';

interface ValueComparisonHOCProps {
  slug: string;
  title: string;
}

const ValueComparisonHOC: React.FC<ValueComparisonHOCProps> = ({
  slug,
  title,
}) => {
  const { selectedProduct } = useContext(FilterContext);
  const { mode } = useContext(ThemeContext);
  const isMobileForValueComparison = useCustomMediaQuery(screenSizes.mobile);
  const {
    getGlobalFilterComparionResData,
    globalFilterComparionResData,
    globalFilterComparionResLoading,
  } = useOverview();

  const { operationalMetricCriteria } = useMetricsCriteria();

  useEffect(() => {
    const psdCriteria = { ...operationalMetricCriteria };
    getGlobalFilterComparionResData({
      variables: { psdCriteria },
    });
  }, [
    getGlobalFilterComparionResData,
    operationalMetricCriteria,
    selectedProduct,
  ]);

  const chartData = processMetricsData(
    globalFilterComparionResData as any,
    mode,
    isMobileForValueComparison
  );

  const transformedData = transformMetricsToDefinitions(
    globalFilterComparionResData as any
  );

  // This check can be updated as we onboard further programs for this Value comparison
  if (selectedProduct?.productId !== constants.products.PepsiConnect) {
    return null;
  }

  return (
    <CardContainer
      slug={slug}
      title={title}
      showInfoIcon={true}
      dataDefinitions={{
        sections: transformedData,
      }}
      isDraggable={true}
      isLoading={globalFilterComparionResLoading}
    >
      {chartData?.metricsData?.length > 0 ? (
        <GridContainer>
          {chartData.metricsData.map((metric) => (
            <KpiContainer
              key={metric.metricId}
              isLoading={globalFilterComparionResLoading}
              title={metric.metricName}
            >
              {metric.chartOptions.series?.length > 0 ? (
                <StackedMutiColumnBarChart
                  legend={{
                    bottom: 0,
                    right: 0,
                    show: metric.chartOptions.legend.show,
                  }}
                  xAxis={{
                    type: 'category',
                    data: metric.chartOptions.xAxis.data,
                    axisTick: metric.chartOptions.xAxis.axisTick,
                  }}
                  yAxis={metric.chartOptions.yAxis}
                  seriesData={metric.chartOptions.series}
                  grid={{
                    left: '3%',
                    right: '4%',
                    bottom: '17%',
                    top: '8%',
                    containLabel: true,
                  }}
                  tooltip={metric.chartOptions.tooltip}
                  eChartHeight={250}
                />
              ) : (
                <Styled.NoDataFoundContainer themeMode={mode}>
                  <div className="noDataFound">
                    <NoDataFound />
                  </div>
                </Styled.NoDataFoundContainer>
              )}
            </KpiContainer>
          ))}
        </GridContainer>
      ) : (
        <Styled.NoDataFoundContainer themeMode={mode}>
          <div className="noDataFound">
            <NoDataFound />
          </div>
        </Styled.NoDataFoundContainer>
      )}
    </CardContainer>
  );
};

export default ValueComparisonHOC;
