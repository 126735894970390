import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const Title = styled.h2`
  margin: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: ${(props) => props.theme?.colors?.textSecondary};
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  letter-spacing: 0px;
  vertical-align: middle;
`;

export const Value = styled.h2`
  margin: 0;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: ${(props) => props.theme?.colors?.textPrimary};
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  letter-spacing: 0px;
  vertical-align: middle;
`;

export const SecondTitle = styled.h2`
  margin: 0;
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 29px;
  height: 40px;
  text-align: left;
  color: ${(props) => props.theme?.colors?.textSecondary};
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const Subtitle = styled.p`
  margin: 0;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: ${(props) => props.theme?.colors?.textSecondary};
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

// Container for all tags, laid out in a column with a gap of 4px
export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

// Row for each tag (tag with its optional side label)
export const TagRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Label = styled.span`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${(props) => props.theme?.colors?.textSecondary};
`;

export const Tag = styled.div`
  font-family: Inter;
  display: flex;
  box-sizing: border-box;
  height: 24px;
  align-items: center;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  flex-wrap: nowrap;
  flex-shrink: 0;

  &.positive {
    background-color: #b3dacb;
  }

  &.negative {
    background-color: #f6cccc;
  }
`;

export const ArrowIcon = styled.span`
  svg {
    margin-bottom: 2px;
    vertical-align: middle;
  }
`;
