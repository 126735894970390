import React, { useState, useRef, useEffect, MouseEvent } from 'react';
import { FiDownload } from 'react-icons/fi';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import {
  subscribeToCompletion,
  subscribeToRequestStatus,
} from 'utils/apolloUtils';
import {
  DownloadButton,
  Tooltip,
  MenuContainer,
  MenuItem,
  Wrapper,
} from './styles';
import { color } from 'theme/Color';

interface Option {
  id: number;
  label: string;
  action: (event?: React.MouseEvent) => void;
}

interface DownloadMenuProps {
  theme: string;
}

const DownloadMenu: React.FC<DownloadMenuProps> = ({ theme }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [tooltipText, setTooltipText] = useState(
    'Enabled on complete page load'
  );
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // Subscribe to ongoing status updates
    const statusUnsubscribe = subscribeToRequestStatus(
      ({ activeRequestCount }) => {
        if (activeRequestCount === 0) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      }
    );

    // Subscribe to completion events
    const completionUnsubscribe = subscribeToCompletion(() => {
      setIsDisabled(false);
    });

    return () => {
      statusUnsubscribe();
      completionUnsubscribe();
    };
  }, []);

  const resetTooltipText = () => {
    setTooltipText('Enabled on complete page load');
  };

  const modifyPdfElements = (clonedDoc: any) => {
    const titleElement = clonedDoc.getElementById('program-title');
    const productTabTitle = clonedDoc.getElementById('pdf-product-title');
    const filterBar = clonedDoc.getElementById('filters');
    const yearFilter = clonedDoc.getElementById('yearValue');
    const pepsiLogo = clonedDoc.querySelector('.pepsicoIcon');
    if (pepsiLogo) {
      pepsiLogo.style.margin = '0';
    }
    if (titleElement && productTabTitle) {
      titleElement.style.display = 'none';
      productTabTitle.style.display = 'block';
    }
    if (filterBar && yearFilter) {
      filterBar.style.display = 'none';
      yearFilter.style.display = 'block';
    }

    return clonedDoc;
  };

  const modifyImageElements = (clonedDoc: any) => {
    const pepsiLogo = clonedDoc.querySelector('.pepsicoIcon');
    if (pepsiLogo) {
      pepsiLogo.style.margin = '0';
    }
    return clonedDoc;
  };

  function getTimestampForFilename() {
    const now = new Date();
    // Format: YYYY-MM-DD_HH-MM-SS
    const timestamp =
      now.getFullYear() +
      '-' +
      String(now.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(now.getDate()).padStart(2, '0') +
      '_' +
      String(now.getHours()).padStart(2, '0') +
      '-' +
      String(now.getMinutes()).padStart(2, '0') +
      '-' +
      String(now.getSeconds()).padStart(2, '0');

    return timestamp;
  }

  const handleExportPDF = async () => {
    setIsOpen(false);
    setIsDisabled(true);
    setTooltipText('Please wait while PDF is being generated');
    const previewContent = document.getElementById('root');
    if (!previewContent) {
      return;
    }

    try {
      const nestedContent = previewContent.querySelector(
        'div[role="tabpanel"]:not([hidden])'
      );
      if (!nestedContent) {
        return;
      }
      const childDiv = nestedContent.querySelector(':scope > div');

      // Add hideScrollbar class from both elements
      nestedContent.classList.add('hideScrollbar');
      childDiv.classList.add('hideScrollbar');

      const totalHeight = previewContent.scrollHeight;
      const viewportHeight = previewContent.clientHeight;

      // Determine the content width. If the sidebar is on the left, adjust accordingly.
      const sideNav = document.getElementById('sidebar');
      const sideNavWidth = sideNav ? sideNav.offsetWidth : 0;
      // Use only the main content area width (ensure your main content is aligned as desired)
      const actualWidth = previewContent.scrollWidth - sideNavWidth;

      // Create a canvas with the actual width and total height
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = actualWidth;
      canvas.height = totalHeight;

      const mainContent =
        document.querySelector('#dashboard') || previewContent;
      const chunkSize = 8000; // pixels
      let capturedHeight = 0;
      const mainHeight = mainContent.scrollHeight;

      while (capturedHeight < totalHeight) {
        const currentChunkHeight = Math.min(
          chunkSize,
          totalHeight - capturedHeight
        );
        previewContent.scrollTop = Math.min(capturedHeight, mainHeight);
        await new Promise((resolve) => setTimeout(resolve, 500));

        // Capture without shifting horizontally (remove x offset if content is already trimmed)
        const partCanvas = await html2canvas(previewContent, {
          scrollX: 0,
          scrollY: capturedHeight,
          useCORS: true,
          scale: window.devicePixelRatio,
          height: currentChunkHeight,
          width: previewContent.scrollWidth,
          // Remove x: sideNavWidth if your content is already correctly positioned
          windowWidth: previewContent.scrollWidth,
          onclone: modifyPdfElements,
          logging: false,
          removeContainer: true,
          backgroundColor: theme === 'light' ? color._FBFCFE : color._131313,
        });

        context.drawImage(
          partCanvas,
          0,
          capturedHeight,
          actualWidth,
          currentChunkHeight
        );
        capturedHeight += currentChunkHeight;

        if (
          previewContent.scrollTop + viewportHeight >=
          previewContent.scrollHeight
        ) {
          break;
        }
      }

      // Convert the canvas to an image
      const imgData = canvas.toDataURL('image/png');

      // Create jsPDF instance (A4 by default)
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
        compress: true,
      });

      // Get the full PDF dimensions
      const pdfPageWidth = pdf.internal.pageSize.getWidth();
      const pdfPageHeight = pdf.internal.pageSize.getHeight();

      // Calculate aspect ratio using the captured content dimensions
      const canvasAspectRatio = actualWidth / totalHeight;

      // Scale the image to fill the full width of the PDF while maintaining the aspect ratio
      let imgWidth = pdfPageWidth;
      let imgHeight = pdfPageWidth / canvasAspectRatio;

      // If the computed height is taller than the PDF, scale down further
      if (imgHeight > pdfPageHeight) {
        imgHeight = pdfPageHeight;
        imgWidth = pdfPageHeight * canvasAspectRatio;
      }

      // Calculate margins to center the image in the PDF
      const marginX = (pdfPageWidth - imgWidth) / 2;
      const marginY = (pdfPageHeight - imgHeight) / 2;

      // Add the image to the PDF
      pdf.addImage(
        imgData,
        'PNG',
        marginX,
        marginY,
        imgWidth,
        imgHeight,
        undefined,
        'FAST'
      );

      // Save the PDF
      pdf.save(`psd-summary-${getTimestampForFilename()}.pdf`);

      // Restore UI adjustments
      nestedContent.classList.remove('hideScrollbar');
      childDiv.classList.remove('hideScrollbar');
      setIsDisabled(false);
      resetTooltipText();
    } catch (error) {
      alert('Failed to generate pdf. Please try again.');
      setIsDisabled(false);
      resetTooltipText();
    }
  };

  const handleImageCopy = async (event: React.MouseEvent) => {
    event.preventDefault();
    setIsOpen(false);
    setIsDisabled(true);
    setTooltipText('Please wait while image is being processed');
    const previewContent = document.getElementById('root');
    if (!previewContent) {
      return;
    }
    // Adjust html2canvas to capture full scrollable content
    try {
      const nestedContent = previewContent.querySelector(
        'div[role="tabpanel"]:not([hidden])'
      );
      if (!nestedContent) {
        return;
      }

      const childDiv = nestedContent.querySelector(':scope > div');

      // Add hideScrollbar class from both elements
      nestedContent.classList.add('hideScrollbar');
      childDiv.classList.add('hideScrollbar');
      const programTitle = document.getElementById('program-title');
      const tabContainer = document.getElementById('tab-container');
      if (tabContainer) {
        tabContainer.removeAttribute('data-html2canvas-ignore');
      }
      const spanInsideStack = programTitle.querySelector(
        'span[data-html2canvas-ignore]'
      );
      if (spanInsideStack) {
        // Remove the attribute from the span
        spanInsideStack.removeAttribute('data-html2canvas-ignore');
      }

      const totalHeight = previewContent.scrollHeight;
      const viewportHeight = previewContent.clientHeight;
      // Create canvas with adjusted width
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const mainContent =
        document.querySelector('#dashboard') || previewContent;

      canvas.width = previewContent.scrollWidth; // actualWidth;
      canvas.height = totalHeight;

      const chunkSize = 7000; // pixels
      let capturedHeight = 0;
      const mainHeight = mainContent.scrollHeight;
      while (capturedHeight < totalHeight) {
        const currentChunkHeight = Math.min(
          chunkSize,
          totalHeight - capturedHeight
        );
        previewContent.scrollTop = Math.min(capturedHeight, mainHeight);
        await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for the scroll to complete
        const partCanvas = await html2canvas(previewContent, {
          scrollX: 0,
          scrollY: capturedHeight,
          useCORS: true,
          scale: window.devicePixelRatio,
          height: currentChunkHeight,
          width: previewContent.scrollWidth, // actualWidth, // previewContent.scrollWidth,
          x: previewContent.offsetLeft, // Offset by sidebar width
          windowWidth: previewContent.scrollWidth, // Force width without sidebar
          onclone: modifyImageElements,
          logging: false, // Enable logging for debugging
          removeContainer: true, // Remove temporary container after capture
          backgroundColor: theme === 'light' ? color._FBFCFE : color._131313, // Preserve transparency
        });

        context.drawImage(
          partCanvas,
          0,
          capturedHeight,
          previewContent.scrollWidth, // previewContent.scrollWidth,
          currentChunkHeight
        );
        capturedHeight += currentChunkHeight;

        // Break the loop if we've reached the end
        if (
          previewContent.scrollTop + viewportHeight >=
          previewContent.scrollHeight
        ) {
          break;
        }
      }

      // Convert to blob after we have the file handle
      const blob = await new Promise<Blob>((resolve) => {
        canvas.toBlob(
          (b) => {
            if (b) {
              resolve(b);
            }
          },
          'image/png',
          1.0
        );
      });
      // Create clipboard items with supported MIME types
      const clipboardItems = [
        new window.ClipboardItem({
          'image/png': blob,
        }),
      ];

      await navigator.clipboard.write(clipboardItems);

      alert('Image copied successfully!');
      nestedContent.classList.remove('hideScrollbar');
      childDiv.classList.remove('hideScrollbar');
      if (tabContainer) {
        tabContainer.setAttribute('data-html2canvas-ignore', '');
      }
      setIsDisabled(false);
      resetTooltipText();
    } catch (error) {
      setIsDisabled(false);
      resetTooltipText();
      alert('Failed to copy image. Please try again.');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !buttonRef.current?.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener(
      'mousedown',
      handleClickOutside as unknown as EventListener
    );
    return () =>
      document.removeEventListener(
        'mousedown',
        handleClickOutside as unknown as EventListener
      );
  }, []);

  const options: Option[] = [
    {
      id: 1,
      label: 'Download PDF',
      action: () => handleExportPDF(),
    },
    {
      id: 2,
      label: 'Copy Image (PNG)',
      action: (event) => handleImageCopy(event as React.MouseEvent),
    },
  ];

  return (
    <Wrapper>
      <DownloadButton
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        disabled={isDisabled}
      >
        <FiDownload size={20} />
        {isDisabled && <Tooltip>{tooltipText}</Tooltip>}
      </DownloadButton>

      {isOpen && (
        <MenuContainer ref={menuRef}>
          {options.map((option) => (
            <MenuItem
              key={option.id}
              onClick={(event: React.MouseEvent) => option.action(event)}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuContainer>
      )}
    </Wrapper>
  );
};

export default DownloadMenu;
