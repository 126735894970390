import React, { useContext } from 'react';
import * as Styled from './style';
import { Grid } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { color as themeColor } from '../../theme/Color';
import { helperFunctions } from '../../utils/helperFunctions';
import { ThemeContext } from '../../context/ThemeContext';
import NoDataFound from '../../components/ErrorHandling/NoDataFound';

const MixLineOverlapBarChart = (props) => {
  const { getValueAbbreviationParser, getNAForInvalidValue } =
    helperFunctions();
  const { mode } = useContext(ThemeContext);
  const chartData = props.data;
  const legendTop = chartData.legendTop;
  const slaResolutionColor = chartData.slaResolutionColor;
  const countOpenCloseColor = chartData.countOpenCloseColor;
  const isMobileView = chartData.isMobileView;
  const isTabletView = chartData.isTabletView;
  const legend = chartData.legend;
  const itemGap = chartData.itemGap;

  function getHeight() {
    if (isMobileView) {
      return '240%';
    } else if (isTabletView) {
      return '225%';
    } else {
      return props?.height || '290%';
    }
  }

  function getItemWidth() {
    if (isMobileView) {
      return 10;
    } else if (isTabletView) {
      return 9;
    } else {
      return 11;
    }
  }

  const option = {
    title: {},
    tooltip: {
      show: 'true',
      position: ['30%', '-5%'],
      valueFormatter: function (value) {
        return getNAForInvalidValue(value);
      },
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999',
        },
      },
    },
    toolbox: {},
    legend: {
      itemWidth: getItemWidth(),
      right: legend?.right,
      itemGap: itemGap,
      top: isTabletView ? '80%' : legendTop,
      data: [
        { name: 'SLA Resolution', icon: 'circle' },
        { name: 'Incident Count', icon: 'circle' },
        { name: 'SLA Respond(%)', icon: 'circle' },
      ],
      textStyle: {
        color: mode === 'light' ? themeColor._616161 : themeColor._c0c0c0,
        fontFamily: 'Inter',
        fontSize: isTabletView ? '13px' : '10px',
        fontWeight: '400',
      },
    },
    grid: {
      right: isMobileView ? 50 : 60,
      top: 20,
      left: 40,
    },
    xAxis: [
      {
        type: 'category',
        axisLine: {
          show: true,
          lineStyle: {
            color: mode === 'light' ? themeColor._616161 : themeColor._4E4E4E,
          },
        },
        data: chartData.dates,
        axisLabel: {
          textStyle: {
            color: mode === 'light' ? themeColor._616161 : themeColor._efefef,
          },
        },
        axisPointer: {
          type: 'shadow',
        },
      },
    ],
    yAxis: [
      {
        //interval: chartData?.countOpenClose?.length < 5 ? 10 : null,
        type: 'value',
        axisLine: {
          show: true,
          lineStyle: {
            color: mode === 'light' ? themeColor._616161 : themeColor._4E4E4E,
          },
        },
        axisLabel: {
          formatter: function (value) {
            return getValueAbbreviationParser(value);
          },
          textStyle: {
            color: mode === 'light' ? themeColor._616161 : themeColor._efefef,
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            color: mode === 'light' ? themeColor._DFDFDF : themeColor._4E4E4E,
          },
        },
      },
      {
        type: 'value',
        min: 0,
        max: 100,
        interval: 20,
        axisLine: {
          show: true,
          lineStyle: {
            color: mode === 'light' ? themeColor._616161 : themeColor._4E4E4E,
          },
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: '{value}%',
          textStyle: {
            color: mode === 'light' ? themeColor._616161 : themeColor._efefef,
          },
        },
      },
    ],
    series: [
      {
        name: 'Incident Count',
        type: 'bar',
        barGap: '-100%',
        barCategoryGap: '80%',
        itemStyle: {
          color: countOpenCloseColor,
          borderRadius: [5, 5, 0, 0],
        },
        data: chartData.countOpenClose,
      },
      {
        name: 'SLA Resolution',
        type: 'bar',
        itemStyle: {
          color: slaResolutionColor,
          borderRadius: [5, 5, 0, 0],
        },
        data: chartData.slaResolution,
      },

      {
        name: 'SLA Respond(%)',
        type: 'line',
        symbolSize: 12,
        symbol: 'circle',
        yAxisIndex: 1,
        itemStyle: {
          color: themeColor._FFA800,
        },
        data: chartData.slaRespond,
      },
    ],
  };

  return (
    <Styled.MixLineOverlapBarChart mode={mode}>
      {chartData?.dates?.length > 0 ? (
        <ReactEcharts
          option={option}
          style={{ width: '100%', height: getHeight() }}
        />
      ) : (
        <Grid
          item
          xs={12}
          className={`noDataFound ${props?.height ? 'customHeight' : ''}`}
        >
          <NoDataFound />
        </Grid>
      )}
    </Styled.MixLineOverlapBarChart>
  );
};

export default MixLineOverlapBarChart;
