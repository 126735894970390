import { helperFunctions } from 'utils/helperFunctions';

const { getValueAbbreviationParser, formatTimeWithSeconds } = helperFunctions();

import {
  formatValueWithMiddlewares,
  checkForNA,
  createKpiTileItem,
  appendPercentage,
  removeTrailingZero,
} from '../../../../../v2/utils/helper';

export const incidentMonitoringScorecrad = (overviewData, ytdCheckbox) => {
  const incidentScoreCardData =
    overviewData?.applicationPeformanceMetricsInOverviewData
      ?.incidentMonitoringOverviewData;
  const appPerfScorecardData = overviewData?.nrmAppPerfScoreCardData;
  const period = ytdCheckbox ? 'vs last year' : 'vs last month';
  return {
    metrics: [
      createKpiTileItem(
        'Avg API Response Time',
        getValueAbbreviationParser(appPerfScorecardData?.avgApiAppResponseTime),
        Number(appPerfScorecardData?.avgApiAppResponseTimeDelta) !== 0
          ? {
              isPositiveValue:
                appPerfScorecardData?.avgApiAppResponseTimeDelta >= 0,
              text: formatValueWithMiddlewares(
                appPerfScorecardData?.avgApiAppResponseTimeDelta,
                [checkForNA, removeTrailingZero, appendPercentage]
              )?.toString(),
              sideLabel: period,
              arrowReversed: false,
            }
          : null
      ),

      createKpiTileItem(
        'Total Number of Errors',
        getValueAbbreviationParser(appPerfScorecardData?.totalNoOfErrors),
        Number(appPerfScorecardData?.totalNoOfErrorsDelta) !== 0
          ? {
              isPositiveValue: appPerfScorecardData?.totalNoOfErrorsDelta >= 0,
              text: formatValueWithMiddlewares(
                appPerfScorecardData?.totalNoOfErrorsDelta,
                [checkForNA, removeTrailingZero, appendPercentage]
              )?.toString(),
              sideLabel: period,
              arrowReversed: false,
            }
          : null
      ),

      createKpiTileItem(
        'Incident Count',
        getValueAbbreviationParser(incidentScoreCardData?.openIncident),
        Number(incidentScoreCardData?.openIncidentDelta) !== 0
          ? {
              isPositiveValue: incidentScoreCardData?.openIncidentDelta < 0,
              text: formatValueWithMiddlewares(
                incidentScoreCardData?.openIncidentDelta,
                [checkForNA, removeTrailingZero, appendPercentage]
              )?.toString(),
              sideLabel: period,
              arrowReversed: true,
            }
          : null
      ),

      createKpiTileItem(
        'Mean Time to Respond',
        formatTimeWithSeconds(incidentScoreCardData?.responseTime),
        Number(incidentScoreCardData?.responseTimeDelta) !== 0
          ? {
              isPositiveValue: incidentScoreCardData?.responseTimeDelta < 0,
              text: formatValueWithMiddlewares(
                incidentScoreCardData?.responseTimeDelta,
                [checkForNA, removeTrailingZero, appendPercentage]
              )?.toString(),
              sideLabel: period,
              arrowReversed: true,
            }
          : null
      ),

      createKpiTileItem(
        'Mean Time to Resolve',
        formatTimeWithSeconds(incidentScoreCardData?.resolutionTime),
        Number(incidentScoreCardData?.resolutionTimeDelta) !== 0
          ? {
              isPositiveValue: incidentScoreCardData?.resolutionTimeDelta < 0,
              text: formatValueWithMiddlewares(
                incidentScoreCardData?.resolutionTimeDelta,
                [checkForNA, removeTrailingZero, appendPercentage]
              )?.toString(),
              sideLabel: period,
              arrowReversed: true,
            }
          : null
      ),
    ],
  };
};
