import {
  formatValueWithMiddlewares,
  checkForNA,
  createKpiTileItem,
  applySuffix,
  replaceNegativeSign,
  formatDollarValue,
  applyDollarSign,
} from '../../../utils/helper';

export default {
  type: 'KpiTile',
  handler: (data) => {
    // Destructure directly from the response data.
    const { totalBaselineSpend, totalBaselineSpendPeriodComparison } =
      data.getArchletScoreCard;

    return createKpiTileItem(
      'Total Baseline Spend',
      formatDollarValue(totalBaselineSpend),
      {
        isPositiveValue: totalBaselineSpendPeriodComparison >= 0,
        text: `${formatValueWithMiddlewares(
          totalBaselineSpendPeriodComparison,
          [checkForNA, replaceNegativeSign, applySuffix, applyDollarSign]
        ).toString()}`,
        sideLabel: '',
        showArrow: totalBaselineSpendPeriodComparison !== 0,
      }
    );
  },
};
