import {
  formatValueWithMiddlewares,
  checkForNA,
  createKpiTileItem,
  removeTrailingZero,
  applySuffix,
  formatNormalNumber,
  replaceNegativeSign,
} from '../../../utils/helper';

export default {
  type: 'KpiTile',
  handler: (data) => {
    // Destructure directly from the response data.
    const { totalProjectsCreated, totalProjectsCreatedPeriodComparison } =
      data.getArchletScoreCard;

    return createKpiTileItem(
      'Total Projects Created',
      formatNormalNumber(totalProjectsCreated),
      {
        isPositiveValue: totalProjectsCreatedPeriodComparison >= 0,
        text: formatValueWithMiddlewares(totalProjectsCreatedPeriodComparison, [
          checkForNA,
          removeTrailingZero,
          replaceNegativeSign,
          applySuffix,
        ]).toString(),
        sideLabel: '',
        showArrow: totalProjectsCreatedPeriodComparison !== 0,
      }
    );
  },
};
