import { color } from 'theme/Color';
import { helperFunctions } from 'utils/helperFunctions';
import { ApiResponse } from '../types';

const { getValueAbbreviationParser, formatTimeWithSeconds } = helperFunctions();

export const processMetricsData = (
  response: ApiResponse,
  mode: string,
  isMobile: boolean
) => {
  const processedMetrics = response?.metrics?.map((metric, index) => {
    // Return the empty chart options if no data is available
    if (!metric?.xaxisLabels?.length) {
      return {
        metricId: metric.metricId,
        metricName: metric.metricDisplayName,
        metricUnit: metric.metricUnit,
        chartOptions: {
          xAxis: { type: 'category', data: [] },
          yAxis: { type: 'value' },
          series: [],
        },
      };
    }

    const colors = [color._33ABDA, color._B05788];
    const barColor = colors[index % colors.length]; // Alternate colors based on the index

    const xAxisData = metric?.xaxisLabels?.map((label) => label.labelName);

    const seriesData = [];

    const series = {
      name: metric?.metricDisplayName,
      data: metric?.xaxisLabels?.map((xLabel) => {
        const rawValue = xLabel?.labelValue;
        // If rawValue is valid, parse it; otherwise, keep as null.
        const numericValue =
          rawValue !== null && rawValue !== undefined && rawValue !== ''
            ? parseFloat(rawValue)
            : null;
        return {
          // Use 0 for plotting if the numeric value is not available.
          value: numericValue !== null ? numericValue : 0,
          // For display (labels and tooltip), use the numeric value or "NA"
          rawValue: numericValue !== null ? numericValue : 'NA',
          stacks: xLabel?.stacks || [],
        };
      }),
      label: {
        show: true,
        position: 'top',
        textStyle: {
          color: mode === 'light' ? `${color._616161}` : `${color._efefef}`,
          fontFamily: 'Inter',
          fontSize: '10px',
          fontStyle: 'normal',
          fontWeight: 700,
        },
        formatter: (params) => {
          // For time metrics, use the helper function.
          if (metric.metricUnit === 'sec') {
            return `${formatTimeWithSeconds(params.value)}`;
          }
          // If the raw value is "NA", simply return "NA"
          if (params.data.rawValue === 'NA') {
            return 'NA';
          }
          // Otherwise, get the abbreviated value.
          const parsedValue = getValueAbbreviationParser(params.value);
          // If the parsed value is "NA", return "NA"; else append unit.
          return parsedValue === 'NA'
            ? 'NA'
            : metric.metricUnit === '$'
              ? `$${parsedValue}` // Place "$" before the value
              : `${parsedValue}${metric.metricUnit}`;
        },
      },
      itemStyle: {
        color: barColor,
      },
      tooltip: {
        trigger: 'item',
        backgroundColor: mode === 'light' ? `${color._272727}` : null,
        textStyle: {
          color: mode === 'light' ? `${color._efefef}` : `${color._616161}`,
          fontFamily: 'Inter',
          fontSize: 12,
          fontStyle: 'normal',
          fontWeight: 400,
        },
        formatter: (params) => {
          // When stacks are available, show Top Markets.
          if (params.data.stacks && params.data.stacks.length > 0) {
            const topMarkets = params.data.stacks
              .map((stack, index) => `${index + 1}. ${stack.stackName}`)
              .join('<br>');
            return `Top Markets<br>${topMarkets}`;
          } else {
            return params.data.rawValue === 'NA'
              ? `${params.name}: NA`
              : metric.metricUnit === '$'
                ? `${params.name}: $${params.data.rawValue}` // Place "$" before the value
                : `${params.name}: ${params.data.rawValue}${metric.metricUnit}`;
          }
        },
      },
      type: 'bar',
      stack: 'total',
      barWidth: isMobile ? 20 : 30,
      connectNulls: true,
    };

    seriesData.push(series);

    return {
      metricId: metric.metricId,
      metricName: metric.metricDisplayName,
      metricUnit: metric.metricUnit,
      chartOptions: {
        xAxis: {
          type: 'category',
          axisTick: {
            show: false,
          },
          data: xAxisData,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: mode === 'light' ? `${color._616161}` : `${color._efefef}`,
            formatter: function (value) {
              if (metric.metricUnit === 'sec') {
                return formatTimeWithSeconds(value);
              }
              const parsedValue =
                value >= 1000 ? getValueAbbreviationParser(value) : value;
              return metric.metricUnit === '$'
                ? `$${parsedValue}` // Place "$" before the value
                : `${parsedValue}${metric.metricUnit}`;
            },
          },
        },
        series: seriesData,
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          show: true,
        },
      },
    };
  });

  return {
    productId: response?.productId,
    productName: response?.productName,
    metricsData: processedMetrics,
  };
};

export const transformMetricsToDefinitions = (response: ApiResponse) => {
  const result = [
    {
      sectionKpiList: response?.metrics?.map((metric) => ({
        kpiName: metric?.metricDisplayName,
        definition: metric?.metricDefinition,
      })),
    },
  ];

  return result;
};
