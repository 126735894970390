import React, { JSX, useEffect, useState } from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';

// (Optional) Declare a global type for widgetJsConfig to avoid using `any`
declare global {
  interface Window {
    widgetJsConfig?: {
      accessToken?: string;
      initWidgetConfig?: (configId: string) => void;
      toggleDarkMode?: (isDarkMode: boolean) => void;
    };
    WIDGET_SERVICE_URI?: string;
  }
}

// Interface representing how your Okta token storage is structured
interface OktaTokenStorage {
  accessToken?: {
    accessToken: string;
  };
}

/* --- GLOBAL STYLES --- */

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-y: scroll;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #eef2f5;
    color: #333;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
`;

/* --- STYLED COMPONENTS --- */

// Main container with increased width
const Container = styled.div`
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 80px);
`;

// Title styling
const Title = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
  color: #2c3e50;
`;

// Card styling for forms and KPI outputs
const FormCard = styled.div`
  background: #fafafa;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem;
`;

// Grid layout for the form inputs
const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1.5rem;
  margin-top: 1.5rem;
`;

// Label styling for form fields
const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  color: #34495e;
`;

// Input styling
const Input = styled.input`
  margin-top: 8px;
  padding: 0.75rem 1rem;
  border: 1px solid #bdc3c7;
  border-radius: 6px;
  font-size: 1rem;
  &:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
  }
`;

// Button styling
const Button = styled.button`
  margin-top: 10px;
  padding: 0.85rem 2rem;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  &:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
  }
  &:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
`;

// Card for displaying the KPI widget
const KpiCard = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;

// KPI header styling
const KpiHeader = styled.h3`
  margin-bottom: 1.5rem;
  color: #2c3e50;
`;

// Container for widget and HTML element display within a tab
const WidgetContainer = styled.div`
  width: 100%;
`;

// HTML element display styling
const HtmlElementDisplay = styled.pre`
  background-color: #ecf0f1;
  padding: 16px;
  border-radius: 6px;
  overflow-x: auto;
  font-family: 'Courier New', Courier, monospace;
  color: #2c3e50;
`;

// Loader animation keyframes and styling
const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;
const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: ${rotate} 1s linear infinite;
  margin: 20px auto;
`;

// Error message styling
const ErrorMessage = styled.div`
  color: #e74c3c;
  text-align: center;
  margin-top: 1rem;
`;

/* --- TAB CONTAINER STYLES --- */

const TabContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
`;

const TabHeader = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
`;

interface TabButtonProps {
  active: boolean;
}

const TabButton = styled.button<TabButtonProps>`
  padding: 0.75rem 1.5rem;
  background-color: ${(props) => (props.active ? '#fff' : '#f7f7f7')};
  border: none;
  border-bottom: ${(props) => (props.active ? '2px solid #3498db' : 'none')};
  cursor: pointer;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  &:hover {
    background-color: #fff;
  }
`;

const TabContent = styled.div`
  padding: 1rem;
`;

// A small styled button for copying code (can reuse the Button component with overrides)
const CopyButton = styled(Button)`
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
`;

/* --- KPI TAB CONTAINER COMPONENT --- */

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

interface KpiTabContainerProps {
  kpiId: string;
}

export const KpiTabContainer: React.FC<KpiTabContainerProps> = ({ kpiId }) => {
  const [activeTab, setActiveTab] = useState<'output' | 'html'>('output');

  // Generate the HTML code string without extra attributes
  const htmlCode = `<div data-widget-kpi-id="${kpiId}" />`;

  // Handler to copy the HTML code to clipboard
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(htmlCode);
      alert('HTML code copied to clipboard!');
    } catch (error) {
      alert('Failed to copy the code.');
    }
  };

  return (
    <TabContainer>
      <TabHeader>
        <TabButton
          active={activeTab === 'output'}
          onClick={() => setActiveTab('output')}
        >
          Output
        </TabButton>
        <TabButton
          active={activeTab === 'html'}
          onClick={() => setActiveTab('html')}
        >
          HTML Code
        </TabButton>
      </TabHeader>
      <TabContent>
        {activeTab === 'output' ? (
          <WidgetContainer>
            <div data-widget-kpi-id={kpiId} style={{ width: '100%' }} />
          </WidgetContainer>
        ) : (
          <>
            <CopyButton type="button" onClick={handleCopy}>
              Copy
            </CopyButton>
            <HtmlElementDisplay>{htmlCode}</HtmlElementDisplay>
          </>
        )}
      </TabContent>
    </TabContainer>
  );
};

/* --- MAIN COMPONENT --- */

function KpiWidgetTest(): JSX.Element {
  // Mapping between KPI IDs and their corresponding config IDs
  const kpiConfigMap: { [key: string]: string } = {
    'rgm-app-root': 'rgm-app-data-config',
    'b2b-steerco-root': 'b2b-steerco-data-config',
    // Add other mappings as needed
  };

  // State for input KPI ID, actual KPI ID, access token, script status and config errors
  const [inputKpiId, setInputKpiId] = useState<string>('rgm-app-root');
  const [kpiId, setKpiId] = useState<string>('');
  const [accessToken, setAccessToken] = useState<string>('');
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
  const [scriptError, setScriptError] = useState<boolean>(false);
  const [configError, setConfigError] = useState<string>('');

  // Retrieve token from localStorage on mount and sync KPI id from query parameter
  useEffect(() => {
    try {
      const storageString = localStorage.getItem('okta-token-storage');
      if (storageString) {
        const tokenData: OktaTokenStorage = JSON.parse(storageString);
        const tokenValue = tokenData?.accessToken?.accessToken;
        if (tokenValue) {
          setAccessToken(tokenValue);
        }
      }
      // Sync the input value with the query parameter when the component mounts
      const urlParams = new URLSearchParams(window.location.search);
      const queryKpiId = urlParams.get('kpiId');
      if (queryKpiId) {
        setKpiId(queryKpiId);
      }
    } catch (error) {
      console.error('Error reading token from localStorage:', error);
    }
  }, []);

  // Update the query parameter when the kpiId state changes
  useEffect(() => {
    if (kpiId) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('kpiId', kpiId);
      window.history.replaceState(null, '', '?' + urlParams.toString());
    }
  }, [kpiId]);

  // Dynamically load the widget script when an access token is available
  useEffect(() => {
    if (accessToken) {
      const WIDGET_URL =
        process.env.WIDGET_SERVICE_URI || window.WIDGET_SERVICE_URI;

      fetch(`${WIDGET_URL}/api/widget-content`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then(async (data) => {
          const widgetContent = data.widget;
          const blob = new Blob([widgetContent], {
            type: 'application/javascript',
          });
          const blobUrl = URL.createObjectURL(blob);
          const script: HTMLScriptElement = document.createElement('script');
          script.src = blobUrl;
          script.async = true;
          script.onload = () => {
            console.log('Widget script loaded');
            setScriptLoaded(true);
            window.widgetJsConfig = window.widgetJsConfig || {};
            window.widgetJsConfig.accessToken = accessToken;
            // Initialization will now be handled in the kpiId/scriptLoaded effect.
            URL.revokeObjectURL(blobUrl);
          };
          script.onerror = () => {
            console.error('Failed to load the widget script.');
            setScriptError(true);
          };
          document.head.appendChild(script);
          return () => {
            document.head.removeChild(script);
          };
        })
        .catch((err) => {
          console.error('Error fetching widget content:', err);
          setScriptError(true);
        });
    }
  }, [accessToken]);

  // Effect to initialize the widget using the mapped config ID when kpiId and script are available
  useEffect(() => {
    if (scriptLoaded && kpiId) {
      const configId = kpiConfigMap[kpiId];
      if (configId && window.widgetJsConfig?.initWidgetConfig) {
        window.widgetJsConfig?.initWidgetConfig?.(configId);
      }
    }
  }, [kpiId, scriptLoaded, kpiConfigMap]);

  // Handler to set KPI and validate config mapping
  const handleSetKpi = () => {
    const configId = kpiConfigMap[inputKpiId];
    if (!configId) {
      setConfigError(`Config ID not available for KPI ID "${inputKpiId}".`);
    } else {
      setConfigError('');
      setKpiId(inputKpiId);
    }
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <Title>Test KPI Widget</Title>

        <FormCard>
          <h3>Configure KPI</h3>
          <FormGrid>
            <Label>
              KPI ID
              <Input
                type="text"
                value={inputKpiId}
                onChange={(e) => setInputKpiId(e.target.value)}
                placeholder="Enter KPI ID"
              />
            </Label>
          </FormGrid>
          <ButtonGroup>
            <Button type="button" onClick={handleSetKpi}>
              Set KPI
            </Button>
            <Button
              type="button"
              onClick={() => window.widgetJsConfig.toggleDarkMode(false)}
            >
              Set Light Mode
            </Button>
            <Button
              type="button"
              onClick={() => window.widgetJsConfig.toggleDarkMode(true)}
            >
              Set Dark Mode
            </Button>
          </ButtonGroup>
          {configError && <ErrorMessage>{configError}</ErrorMessage>}
        </FormCard>

        {scriptError ? (
          <ErrorMessage>
            Failed to load the widget script. Please try again later.
          </ErrorMessage>
        ) : !scriptLoaded ? (
          <Loader />
        ) : (
          <>
            {!kpiId ? (
              <p>
                Please enter a KPI ID and click &quot;Set KPI&quot; to display
                the widget.
              </p>
            ) : (
              <KpiCard>
                <KpiHeader>KPI Output - {kpiId}</KpiHeader>
                <KpiTabContainer kpiId={kpiId} />
              </KpiCard>
            )}
          </>
        )}
      </Container>
    </>
  );
}

export default KpiWidgetTest;
