import {
  formatNormalNumber,
  createKpiTileItem,
} from '../../../../utils/helper';

export default {
  type: 'KpiTile',
  handler: (data) => {
    const { totalUser } = data.getUserAdoptionScorecard;

    return createKpiTileItem(
      'Total Users',
      formatNormalNumber(totalUser),
      null
    );
  },
};
