import {
  formatValueWithMiddlewares,
  checkForNA,
  createKpiTileItem,
  removeTrailingZero,
  applySuffix,
  replaceNegativeSign,
  formatDollarValue,
  applyDollarSign,
} from '../../../utils/helper';

export default {
  type: 'KpiTile',
  handler: (data) => {
    // Destructure directly from the response data.
    const {
      valueOpportunityIdentified,
      valueOpportunityIdentifiedPeriodComparison,
    } = data.getArchletScoreCard;

    return createKpiTileItem(
      'Value Opportunity Identified',
      formatDollarValue(valueOpportunityIdentified),
      {
        isPositiveValue: valueOpportunityIdentifiedPeriodComparison >= 0,
        text: formatValueWithMiddlewares(
          valueOpportunityIdentifiedPeriodComparison,
          [
            checkForNA,
            replaceNegativeSign,
            removeTrailingZero,
            applySuffix,
            applyDollarSign,
          ]
        ),
        sideLabel: '',
        showArrow: valueOpportunityIdentifiedPeriodComparison !== 0,
      }
    );
  },
};
