import React from 'react';
import { IncidentCountViewStyles as Styled } from './styles';
import KpiGrid from 'components/redesign/KpiGrid';
import KpiTile from 'components/redesign/KpiTile';

interface IncidentCountViewProps {
  metrics: any[];
  columns?: number;
}

const IncidentCountView: React.FC<IncidentCountViewProps> = ({
  metrics,
  columns,
}) => {
  return (
    <Styled.DashboardContainer>
      <KpiGrid
        columnsDesktop={columns ? columns : 3}
        columnsTablet={columns ? columns : 3}
        columnsMobile={2}
        showHDividerDesktop={false}
        showHDividerTablet={false}
        showHDividerMobile={false}
      >
        {metrics?.map((metric, index) => <KpiTile key={index} data={metric} />)}
      </KpiGrid>
    </Styled.DashboardContainer>
  );
};

export default IncidentCountView;
