import React from 'react';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import { KpiTileProps } from './types';
import {
  Container,
  Title,
  Value,
  SecondTitle,
  Subtitle,
  TagContainer,
  TagRow,
  Label,
  Tag,
  ArrowIcon,
} from './style';

const KpiTile: React.FC<KpiTileProps> = ({ data }) => {
  const { title, secondTitle, value, tag: tags, subTitle } = data;

  // Function to render each tag
  const renderTag = (tagItem: any, index: number) => {
    const arrowReversed = tagItem.arrowReversed ?? false;
    const ArrowComponent = tagItem.isPositiveValue
      ? arrowReversed
        ? MdArrowDownward
        : MdArrowUpward
      : arrowReversed
        ? MdArrowUpward
        : MdArrowDownward;

    const tagValue =
      (tagItem.showArrow ?? true) ? (
        <span>
          {tagItem.text}{' '}
          <ArrowIcon>
            <ArrowComponent />
          </ArrowIcon>
        </span>
      ) : (
        tagItem.text
      );

    return (
      <TagRow key={index}>
        <Tag
          className={tagItem.isPositiveValue ? 'positive' : 'negative'}
          data-testid={`tile-tag-${index}`}
        >
          {tagValue}
        </Tag>
        {tagItem.sideLabel && (
          <Label data-testid={`tile-tag-label-${index}`}>
            {tagItem.sideLabel}
          </Label>
        )}
      </TagRow>
    );
  };

  return (
    <Container data-testid="tile-container">
      <Value
        data-testid="tile-value"
        dangerouslySetInnerHTML={{ __html: value as string }}
      />
      <Title data-testid="tile-title">{title}</Title>
      {secondTitle && (
        <SecondTitle data-testid="tile-second-title">{secondTitle}</SecondTitle>
      )}
      {tags && Array.isArray(tags) && tags.length > 0 && (
        <TagContainer data-testid="tile-tag-container">
          {tags.map((tagItem, index) => renderTag(tagItem, index))}
        </TagContainer>
      )}
      {subTitle && <Subtitle data-testid="tile-subtitle">{subTitle}</Subtitle>}
    </Container>
  );
};

export default KpiTile;
