import React, { lazy, Suspense } from 'react';
import CustomLoader from '../components/CustomLoader/CustomLoader';

/**
 * withLazyLoad dynamically imports a component given its module path,
 * and wraps it with a Suspense boundary displaying <ContainerCard /> while loading.
 *
 * @param {string} modulePath - The relative path to the component module.
 * @returns {React.Component} - A component that lazy loads the target component.
 */
const withLazyLoad = (modulePath, isCardLoader = false) => {
  // Create a lazy-loaded component using the provided module path.
  const LazyComponent = lazy(modulePath);

  // Return a component that renders LazyComponent within a Suspense boundary.
  return function LazyLoadedComponent(props) {
    return (
      <Suspense fallback={<CustomLoader isCardLoader={isCardLoader} />}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };
};

export default withLazyLoad;
