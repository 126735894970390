import React, { useContext, useEffect, useState } from 'react';
import CardContainer from '../../../../components/redesign/CardContainer';
import { KpiData } from 'components/redesign/KpiTile/types';
import KpiGrid from '../../../../components/redesign/KpiGrid';
import KpiDefinitionData from 'service/KpiDefinitionData';
import { FilterContext } from 'components/FilterBar/FilterProvider';
import { useNrm } from 'hooks/nrm-hook';
import { useMetricsCriteria } from 'hooks/useMetricsCriteria';
import KpiTile from 'components/redesign/KpiTile';
import promotionsSaved from '../../../../v2/kpis/rgm/app/valueCreation/promotionsSaved';
import scenariosEdited from '../../../../v2/kpis/rgm/app/valueCreation/scenariosEdited';

interface ScoreCardProps {
  title: string;
}
const ScoreCard: React.FC<ScoreCardProps> = ({ title }) => {
  const [overviewConfig, setOverviewConfig] = useState<KpiData[]>([]);
  const { selectedProduct } = useContext(FilterContext);
  const { operationalMetricCriteria } = useMetricsCriteria();
  const {
    getRGMAPPValueCreationScorecardData,
    rgmAPPValueCreationScorecardData,
    getRGMAPPValueCreationScorecardLoading,
  } = useNrm();

  const generateScoreCardItems = (data: any) => {
    return [promotionsSaved.handler(data), scenariosEdited.handler(data)];
  };

  useEffect(() => {
    const psdCriteria = { ...operationalMetricCriteria };
    getRGMAPPValueCreationScorecardData({
      variables: { psdCriteria },
    });
  }, [getRGMAPPValueCreationScorecardData, operationalMetricCriteria]);

  useEffect(() => {
    if (rgmAPPValueCreationScorecardData) {
      setOverviewConfig(
        generateScoreCardItems({ ...rgmAPPValueCreationScorecardData })
      );
    }
  }, [rgmAPPValueCreationScorecardData]);

  return (
    <CardContainer
      title={title}
      showInfoIcon={true}
      dataDefinitions={{
        sections:
          KpiDefinitionData[selectedProduct?.productId]?.valueCreation
            ?.summary ?? [],
      }}
      isAccordion={false}
      isLoading={getRGMAPPValueCreationScorecardLoading}
    >
      <KpiGrid columnsDesktop={2} columnsTablet={2} columnsMobile={2}>
        {overviewConfig.map((item, index) => (
          <KpiTile key={index} data={item} />
        ))}
      </KpiGrid>
    </CardContainer>
  );
};

export default ScoreCard;
