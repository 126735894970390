import { helperFunctions } from 'utils/helperFunctions';
import { color } from 'theme/Color';

const {
  getDateAndMonth,
  getValueAbbreviationParser,
  formatTimeWithSeconds,
  getNAForPercentageValue,
} = helperFunctions();

import {
  formatValueWithMiddlewares,
  checkForNA,
  createKpiTileItem,
  appendPercentage,
  removeTrailingZero,
} from '../../../../../v2/utils/helper';

export const createAppPerformanceData = (
  applicationPeformanceMetricsInOverviewData,
  ytdCheckbox
) => {
  const appDScoreCardData =
    applicationPeformanceMetricsInOverviewData?.healthAndStabilityData;
  const incidentScoreCardData =
    applicationPeformanceMetricsInOverviewData?.incidentMonitoringOverviewData;
  const period = ytdCheckbox ? 'vs last year' : 'vs last month';
  return {
    metrics: [
      // App Crash Rate metric (positive delta is good)
      createKpiTileItem(
        'App Crash Rate',
        getNAForPercentageValue(appDScoreCardData?.appCrashes),
        Number(appDScoreCardData?.appCrashesDelta) !== 0
          ? {
              isPositiveValue: appDScoreCardData?.appCrashesDelta < 0,
              text: formatValueWithMiddlewares(
                appDScoreCardData?.appCrashesDelta,
                [checkForNA, removeTrailingZero, appendPercentage]
              ).toString(),
              sideLabel: period,
              arrowReversed: true,
            }
          : null
      ),
      // // Average API Latency metric (positive delta is good)
      createKpiTileItem(
        'Average API Latency',
        formatTimeWithSeconds(appDScoreCardData?.networkLatency),
        Number(appDScoreCardData?.networkLatencyDelta) !== 0
          ? {
              isPositiveValue: appDScoreCardData?.networkLatencyDelta < 0,
              text: formatValueWithMiddlewares(
                appDScoreCardData?.networkLatencyDelta,
                [checkForNA, removeTrailingZero, appendPercentage]
              ).toString(),
              sideLabel: period,
              arrowReversed: true,
            }
          : null
      ),

      createKpiTileItem(
        Number(appDScoreCardData?.networkErrors || 0) > 1
          ? 'Network Errors'
          : 'Network Error',
        getValueAbbreviationParser(appDScoreCardData?.networkErrors),
        Number(appDScoreCardData?.networkErrorDelta) !== 0
          ? {
              isPositiveValue: appDScoreCardData?.networkErrorDelta < 0,
              text: formatValueWithMiddlewares(
                appDScoreCardData?.networkErrorDelta,
                [checkForNA, removeTrailingZero, appendPercentage]
              ).toString(),
              sideLabel: period,
              arrowReversed: true,
            }
          : null
      ),

      createKpiTileItem(
        Number(incidentScoreCardData?.openIncident || 0) > 1
          ? 'Open Incidents'
          : 'Open Incident',
        getValueAbbreviationParser(incidentScoreCardData?.openIncident),
        Number(incidentScoreCardData?.openIncidentDelta) !== 0
          ? {
              isPositiveValue: incidentScoreCardData?.openIncidentDelta < 0,
              text: formatValueWithMiddlewares(
                incidentScoreCardData?.openIncidentDelta,
                [checkForNA, removeTrailingZero, appendPercentage]
              ).toString(),
              sideLabel: period,
              arrowReversed: true,
            }
          : null
      ),

      createKpiTileItem(
        'Mean Time to Respond',
        formatTimeWithSeconds(incidentScoreCardData?.responseTime),
        Number(incidentScoreCardData?.responseTimeDelta) !== 0
          ? {
              isPositiveValue: incidentScoreCardData?.responseTimeDelta < 0,
              text: formatValueWithMiddlewares(
                incidentScoreCardData?.responseTimeDelta,
                [checkForNA, removeTrailingZero, appendPercentage]
              ).toString(),
              sideLabel: period,
              arrowReversed: true,
            }
          : null
      ),

      createKpiTileItem(
        'Mean Time to Resolve',
        formatTimeWithSeconds(incidentScoreCardData?.resolutionTime),
        Number(incidentScoreCardData?.resolutionTimeDelta) !== 0
          ? {
              isPositiveValue: incidentScoreCardData?.resolutionTimeDelta < 0,
              text: formatValueWithMiddlewares(
                incidentScoreCardData?.resolutionTimeDelta,
                [checkForNA, removeTrailingZero, appendPercentage]
              ).toString(),
              sideLabel: period,
              arrowReversed: true,
            }
          : null
      ),
    ],
  };
};

export const createDataList = (data, isMobile, isTablet) => {
  const chartData = data?.incidentTrendData;
  const datesForGraph = [];
  const resolvedWithInSlaForGraph = [];
  const incidentCountForGraph = [];
  const responseWithInSlaForGraph = [];

  function getRightGap() {
    if (isMobile) {
      return '10%';
    } else if (isTablet) {
      return '5%';
    } else {
      return '6%';
    }
  }

  function getItemGap() {
    if (isMobile) {
      return 8;
    } else if (isTablet) {
      return 6;
    } else {
      return 25;
    }
  }

  const legend = {
    right: getRightGap(),
  };

  chartData?.forEach((data) => {
    datesForGraph.push(getDateAndMonth(data.dateTime));
    resolvedWithInSlaForGraph.push(data.resolvedWithInSla);
    incidentCountForGraph.push(data.incidentCount);
    responseWithInSlaForGraph.push(data.responseWithInSla);
  });

  return {
    dates: datesForGraph,
    slaResolution: resolvedWithInSlaForGraph,
    countOpenClose: incidentCountForGraph,
    slaRespond: responseWithInSlaForGraph,
    legendTop: '85%',
    slaResolutionColor: color._007EB5,
    countOpenCloseColor: color._824061,
    isMobileView: isMobile,
    isTabletView: isTablet,
    legend: legend,
    itemGap: getItemGap(),
  };
};
