import styled from 'styled-components';
import { breakpoints, screenSizes } from 'constants/Constants';

interface IncidentCountProps {
  type?: 'success' | 'warning';
}

export const IncidentCountViewStyles = {
  DashboardContainer: styled.div`
    max-width: 800px;
    @media (max-width: 1024px) {
      max-width: 100%;
    }
  `,

  MetricsGrid: styled.div`
      display: grid;
      row-gap: 40px; // Increased gap for separator spacing
  
      // Mobile layout (2 columns)
      grid-template-columns: repeat(2, 1fr);
  
       @media ${screenSizes?.tablet},
           ${screenSizes?.ipadMini},
           ${screenSizes?.ipadAir},
           ${screenSizes?.ipadPro} {
          grid-template-columns: repeat(3, 1fr);
      }
  
      // Tablet layout (3 columns)
      @media (min-width: ${breakpoints?.tablet} and (max-width: ${breakpoints?.desktop})) {
        grid-template-columns: repeat(3, 1fr);
      }
  
      // Desktop layout (5 columns)
      @media (min-width: ${breakpoints?.desktop}) {
        grid-template-columns: repeat(3, 1fr);
      }
  
      & > * {
        position: relative;
  
        &:not(:nth-child(2n + 1)) {
          border-left: 1px solid ${(props) => props.theme?.colors?.verticalDivider};
        }
  
        @media ${screenSizes?.tablet},
           ${screenSizes?.ipadMini},
           ${screenSizes?.ipadAir},
           ${screenSizes?.ipadPro} {
          &:not(:nth-child(3n + 1)) {
            border-left: 1px solid ${(props) => props.theme?.colors?.verticalDivider};
          }
          // Add padding-left: 24px to the 2nd and 3rd items of each row
          &:nth-child(3n + 2),
          &:nth-child(3n + 3) {
            padding-left: 24px;
          }
          // Remove border from 4th item in each row
          &:nth-child(3n + 4) {
            border-left: none;
          }
        }
  
        @media (min-width: ${breakpoints?.desktop}) {
          &:not(:nth-child(3n + 1)) {
            border-left: 1px solid ${(props) => props.theme?.colors?.verticalDivider};
            padding-left: 24px;
          }
  
          // Remove border from 4th item in each row
          &:nth-child(3n + 4) {
            border-left: none;
          }
        }
  
        @media ${screenSizes?.mobile}, {
          &:not(:nth-child(2n + 1)) {
            padding-left: 16px;
          }
        }
    `,

  MetricCard: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 0;
  `,

  Value: styled.div`
    font-size: 16px;
    color: ${(props) => props.theme?.colors?.textPrimary};
    font-family: Inter;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    @media (max-width: 480px) {
      font-size: 16px;
    }
  `,

  Label: styled.div`
    color: ${(props) => props.theme?.colors?.textSecondary};
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  `,

  BottomRow: styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  `,

  ChangeIndicator: styled.div<IncidentCountProps>`
    display: flex;
    align-items: center;
    padding: 2px 4px;
    border-radius: 4px;
    gap: 4px;
    background-color: ${(props) => {
      if (props.type === 'success') {
        return '#B3DACB';
      }
      if (props.type === 'warning') {
        return '#F6CCCC';
      }
    }};
    color: #3a3a3a;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    svg {
      width: 14px;
      height: 14px;
      margin-right: 2px;

      @media (max-width: 480px) {
        width: 10px;
        height: 10px;
      }
    }
  `,

  Period: styled.span`
    color: ${(props) => props.theme?.colors?.textSecondary};
    font-size: 12px;
    font-weight: 400;
    font-family: Inter;
    line-height: 16.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  `,
};
