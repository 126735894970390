import React, { useContext, useEffect, useState } from 'react';
import KpiDefinitionData from 'service/KpiDefinitionData';
import { useOverview } from '../../../../hooks/overview-hook';
import { useMetricsCriteria } from '../../../../hooks/useMetricsCriteria';
import { FilterContext } from '../../../../components/FilterBar/FilterProvider';
import CardContainer from '../../../../components/redesign/CardContainer';
import { KpiData } from 'components/redesign/KpiTile/types';
import KpiGrid from '../../../../components/redesign/KpiGrid';
import KpiTile from 'components/redesign/KpiTile';
import totalUsers from '../../../../v2/kpis/rgm/app/userAdoption/totalUsers';
import activeUsers from '../../../../v2/kpis/rgm/app/userAdoption/activeUsers';
import adoptionRate from '../../../../v2/kpis/rgm/app/userAdoption/adoptionRate';
import totalAccountsOnboarded from '../../../../v2/kpis/rgm/app/userAdoption/totalAccountsOnboarded';
import { constants } from 'constants/Constants';

interface ValueCreationNrmProps {
  title: string;
}
const SummaryMetricsRgmApp: React.FC<ValueCreationNrmProps> = ({ title }) => {
  const [overViewConfig, setOverviewConfig] = useState<KpiData[]>([]);
  const {
    rgmAppUserAdoptionScorecard,
    getRgmAppUserAdoption,
    getRgmAppUserAdoptionLoading,
  } = useOverview() as any;
  const { operationalMetricCriteria } = useMetricsCriteria();
  const { selectedProduct } = useContext(FilterContext);

  useEffect(() => {
    if (
      selectedProduct?.productId == constants?.products?.APP ||
      selectedProduct?.productId == constants?.products?.Archlet
    ) {
      const overviewMetricCriteria = { ...operationalMetricCriteria };
      getRgmAppUserAdoption({
        variables: { overviewMetricCriteria },
      });
    }
  }, [getRgmAppUserAdoption, operationalMetricCriteria, selectedProduct]);

  useEffect(() => {
    if (rgmAppUserAdoptionScorecard) {
      const data = {
        getUserAdoptionScorecard: { ...rgmAppUserAdoptionScorecard },
      };
      // Set the overview configuration with the existing handlers
      const updatedOverviewConfig = [
        totalUsers.handler(data),
        activeUsers.handler(data),
        adoptionRate.handler(data),
      ];

      // Add totalAccountsOnboarded handler only if selected product is APP
      if (selectedProduct?.productId === constants?.products?.APP) {
        updatedOverviewConfig.push(totalAccountsOnboarded.handler(data));
      }

      // Set the updated overview config
      setOverviewConfig(updatedOverviewConfig);
    }
  }, [rgmAppUserAdoptionScorecard]);

  return (
    <CardContainer
      isLoading={getRgmAppUserAdoptionLoading}
      title={title}
      showInfoIcon={true}
      dataDefinitions={{
        sections:
          KpiDefinitionData[selectedProduct?.productId]
            ?.userBehaviorAndEngagement?.userAdoption ?? [],
      }}
      isAccordion={false}
    >
      <KpiGrid
        columnsDesktop={overViewConfig.length === 4 ? 4 : 3}
        columnsTablet={overViewConfig.length === 4 ? 4 : 3}
        columnsMobile={1}
      >
        {overViewConfig.map((item, index) => (
          <KpiTile key={index} data={item} />
        ))}
      </KpiGrid>
    </CardContainer>
  );
};

export default SummaryMetricsRgmApp;
