import React, { useContext, useEffect, useCallback } from 'react';
import * as Styled from './style';
import DatePicker from 'react-datepicker';
import { color } from '../../theme/Color';
import { MdOutlineCalendarToday } from 'react-icons/md';
import { constants } from '../../constants/Constants';
import { ThemeContext } from '../../context/ThemeContext';
import { FilterContext } from './FilterProvider';
import { determineYearToUse } from './helper';

export function CustomDatePicker({
  setSelectedStartDate,
  setStartUTCDate,
  setSelectedEndDate,
  setEndUTCDate,
  getStartOfMonth,
  getEndOfMonth,
  getCurrentDayOfMonth,
  portalId,
  product,
  pickerType,
  minSelectableYear,
  maxSelectableYear,
  setSelectedYear,
  selectedYear,
  selectedDate,
  setSelectedDate,
  checked,
  goLiveMonth,
  selectedDateForMonthPicker,
  setSelectedDateForMonthPicker,
}) {
  const {
    selectedMarket,
    selectedCustomer,
    selectedProduct,
    selectedModel,
    selectedSector,
  } = useContext(FilterContext);

  const setDateRangeAndUTC = (
    startDate,
    endDate,
    {
      setSelectedStartDate,
      setStartUTCDate,
      setSelectedEndDate,
      setEndUTCDate,
      getStartOfMonth,
      getEndDate,
    }
  ) => {
    setSelectedStartDate(startDate);
    setStartUTCDate(getStartOfMonth(startDate)?.toISOString());

    const endOfMonth = getEndDate(endDate);
    setSelectedEndDate(endOfMonth);
    setEndUTCDate(endOfMonth?.toISOString());
  };

  const getEndDateFn = (date, isShipmentVisibility) => {
    return isShipmentVisibility && isCurrentDate(date)
      ? getCurrentDayOfMonth()
      : getEndOfMonth(date);
  };

  const handleYtdModeOn = useCallback(
    (params) => {
      const {
        selectedYear,
        minSelectableYear,
        currentYear,
        currentMonth,
        isShipmentVisibility,
      } = params;

      const yearToUse = determineYearToUse(selectedYear, minSelectableYear);
      const isCurrentYear = yearToUse === currentYear;

      const newDate = new Date(yearToUse, isCurrentYear ? currentMonth : 11, 1);

      setSelectedYear(yearToUse);
      setSelectedDate(newDate);
      setSelectedDateForMonthPicker(newDate);

      const startOfYear = new Date(yearToUse, 0, 1);

      const getEndDate = (date) => getEndDateFn(date, isShipmentVisibility);

      setDateRangeAndUTC(startOfYear, newDate, {
        setSelectedStartDate,
        setStartUTCDate,
        setSelectedEndDate,
        setEndUTCDate,
        getStartOfMonth,
        getEndDate,
      });
    },
    [
      setSelectedYear,
      setSelectedDate,
      setSelectedDateForMonthPicker,
      setSelectedStartDate,
      setStartUTCDate,
      setSelectedEndDate,
      setEndUTCDate,
    ]
  );

  const updateDateForMinSelectableYear = useCallback(
    (params) => {
      const { minSelectableYear, goLiveMonth, isShipmentVisibility } = params;

      setSelectedYear(minSelectableYear);

      const newDate = new Date(minSelectableYear, goLiveMonth, 1);
      setSelectedDate(newDate);
      setSelectedDateForMonthPicker(newDate);

      const getEndDate = (date) => getEndDateFn(date, isShipmentVisibility);

      setDateRangeAndUTC(newDate, newDate, {
        setSelectedStartDate,
        setStartUTCDate,
        setSelectedEndDate,
        setEndUTCDate,
        getStartOfMonth,
        getEndDate,
      });
    },
    [
      setSelectedYear,
      setSelectedDate,
      setSelectedDateForMonthPicker,
      setSelectedStartDate,
      setStartUTCDate,
      setSelectedEndDate,
      setEndUTCDate,
    ]
  );

  const adjustDateForGoLiveMonth = useCallback(
    (params) => {
      const { selectedYear, goLiveMonth, isShipmentVisibility } = params;

      setSelectedDate((prevDate) => {
        const prevMonth = prevDate.getMonth();
        if (goLiveMonth > prevMonth) {
          const newDate = new Date(selectedYear, goLiveMonth, 1);

          const getEndDate = (date) => getEndDateFn(date, isShipmentVisibility);

          setDateRangeAndUTC(newDate, newDate, {
            setSelectedStartDate,
            setStartUTCDate,
            setSelectedEndDate,
            setEndUTCDate,
            getStartOfMonth,
            getEndDate,
          });
          setSelectedDateForMonthPicker(newDate);
          return newDate;
        }
        return prevDate;
      });
    },
    [
      setSelectedDate,
      setSelectedDateForMonthPicker,
      setSelectedStartDate,
      setStartUTCDate,
      setSelectedEndDate,
      setEndUTCDate,
    ]
  );

  const handleYtdModeOff = useCallback(
    (params) => {
      const {
        selectedYear,
        minSelectableYear,
        goLiveMonth,
        isShipmentVisibility,
      } = params;

      if (!minSelectableYear || goLiveMonth === undefined) {
        return;
      }

      if (selectedYear < minSelectableYear) {
        updateDateForMinSelectableYear({
          minSelectableYear,
          goLiveMonth,
          isShipmentVisibility,
        });
      } else if (selectedYear === minSelectableYear) {
        adjustDateForGoLiveMonth({
          selectedYear,
          goLiveMonth,
          isShipmentVisibility,
        });
      }
    },
    [updateDateForMinSelectableYear, adjustDateForGoLiveMonth]
  );

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const isShipmentVisibility =
      selectedProduct?.productId === constants?.products?.ShipmentVisibilty;

    const params = {
      selectedYear,
      minSelectableYear,
      goLiveMonth,
      currentYear,
      currentMonth,
      isShipmentVisibility,
    };

    if (checked) {
      handleYtdModeOn(params);
    } else {
      handleYtdModeOff(params);
    }
  }, [
    checked,
    selectedYear,
    selectedProduct,
    minSelectableYear,
    goLiveMonth,
    selectedMarket,
    selectedCustomer,
    selectedModel,
    selectedSector,
    handleYtdModeOn,
    handleYtdModeOff,
  ]);

  const isCurrentDate = (date) => {
    const today = new Date();
    return (
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };
  const { mode } = useContext(ThemeContext);

  const getDateValueForMonthPicker = (currentYear, year) => {
    const selectedMonth = selectedDateForMonthPicker?.getMonth();

    // If we're in the current year, return the current date
    if (currentYear === year) {
      const currentMonth = new Date().getMonth(); // Get current month
      // If the selected month is in the future (greater than current month), reset to current month

      const adjustedMonth =
        selectedMonth > currentMonth ? currentMonth : selectedMonth;
      return new Date(year, adjustedMonth, 1);
    }

    // If it's the minimum selectable year and YTD is toggled off
    if (year === minSelectableYear && !checked) {
      // If the selected month is before the go-live month, reset it to goLiveMonth
      const adjustedMonth =
        selectedMonth < goLiveMonth ? goLiveMonth : selectedMonth;
      return new Date(year, adjustedMonth, 1);
    }

    // If the selected year is greater than the minSelectableYear and less than the current year ,
    // just keep the already selected month as it is
    if (year > minSelectableYear && year < currentYear && !checked) {
      return new Date(year, selectedMonth, 1);
    }

    // Default to December if no conditions match
    return new Date(year, 11, 1);
  };

  const handleDateChangeLogic = (date) => {
    const year = date.getFullYear();
    const currentYear = new Date().getFullYear();

    if (pickerType === 'year') {
      setSelectedYear(year);
      const dateValue = getDateValueForMonthPicker(currentYear, year);
      setSelectedDate(dateValue);
      setSelectedDateForMonthPicker(dateValue);
      setSelectedStartDate(dateValue);
      const startUTCDate = checked
        ? getStartOfMonth(date)?.toISOString()
        : getStartOfMonth(dateValue)?.toISOString();
      setStartUTCDate(startUTCDate); // Assigning the date value selected from year picker
      if (
        isCurrentDate(date) &&
        product?.productId === constants?.products?.ShipmentVisibilty
      ) {
        const currentDate = getCurrentDayOfMonth();
        setSelectedEndDate(currentDate);
        setEndUTCDate(currentDate?.toISOString());
      } else {
        const endOfMonth = getEndOfMonth(date);
        setSelectedEndDate(endOfMonth);
        setEndUTCDate(endOfMonth?.toISOString());
      }
      const endOfMonth = getEndOfMonth(dateValue);
      setSelectedEndDate(endOfMonth);
      setEndUTCDate(endOfMonth?.toISOString());
    } else if (pickerType === 'month') {
      setSelectedDate(date);
      setSelectedDateForMonthPicker(date);
      setSelectedStartDate(date);
      setStartUTCDate(getStartOfMonth(date)?.toISOString());

      if (
        isCurrentDate(date) &&
        product?.productId === constants?.products?.ShipmentVisibilty
      ) {
        const currentDate = getCurrentDayOfMonth();
        setSelectedEndDate(currentDate);
        setEndUTCDate(currentDate?.toISOString());
      } else {
        const endOfMonth = getEndOfMonth(date);
        setSelectedEndDate(endOfMonth);
        setEndUTCDate(endOfMonth?.toISOString());
      }
    }
  };

  const handleSelectedDate = () => {
    return pickerType === 'year' ? new Date(selectedYear, 0, 1) : selectedDate;
  };

  const getMinDateForDatePicker = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    const minLocalSelectableYear = isNaN(minSelectableYear)
      ? currentYear
      : minSelectableYear;
    const goLocalLiveMonth = isNaN(goLiveMonth) ? currentMonth : goLiveMonth;

    if (pickerType === 'year') {
      return new Date(minLocalSelectableYear, 0, 1);
    } else {
      return new Date(minLocalSelectableYear, goLocalLiveMonth, 1);
    }
  };

  const getMaxDate = (pickerType, selectedYear, maxSelectableYear) => {
    if (pickerType === 'year') {
      return new Date(maxSelectableYear, 11, 31);
    } else if (new Date().getFullYear() === selectedYear) {
      return new Date();
    } else {
      return new Date(selectedYear, 11, 31);
    }
  };

  return (
    <Styled.DatePickerContainer mode={mode} style={{ position: 'relative' }}>
      <Styled.GlobalStyle
        mode={mode}
        disabled={pickerType === 'month' && checked}
        pickerType={pickerType}
      />
      <label>
        <DatePicker
          id="date-filter"
          data-testid="date-filter"
          portalId={portalId}
          className={
            pickerType === 'month' && checked
              ? 'disabledFilter filter'
              : 'filter'
          }
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onChange={(date) => {
            // Process the date selection
            handleDateChangeLogic(date);
            // Close the calendar if it’s open
            setTimeout(() => {
              const filterElements = document.getElementsByClassName('filter');
              Array.from(filterElements).forEach((element) => element.blur());
            }, 100);
          }}
          disabledKeyboardNavigation
          placeholderText={pickerType === 'year' ? 'Year' : 'Month'}
          enableTabLoop={false}
          showMonthYearPicker={pickerType === 'month'}
          showYearPicker={pickerType === 'year'}
          selected={handleSelectedDate()}
          dateFormat={pickerType === 'year' ? 'yyyy' : 'MMMM'}
          minDate={getMinDateForDatePicker()}
          maxDate={getMaxDate(pickerType, selectedYear, maxSelectableYear)}
          showDisabledMonthNavigation={false}
          readOnly={pickerType === 'month' && checked}
          renderCustomHeader={
            pickerType === 'year'
              ? () => <div>2023-2028</div>
              : () => <div>{selectedYear}</div>
          }
        />
        <Styled.IconContainer>
          <MdOutlineCalendarToday
            data-accessible={true}
            style={{
              color:
                pickerType === 'month' && checked ? 'darkgray' : color._616161,
              cursor: 'pointer',
            }}
            data-testid="datepicker_calendar"
          />
        </Styled.IconContainer>
      </label>
    </Styled.DatePickerContainer>
  );
}
