import React, { useContext, useEffect } from 'react';
import CardContainer from 'components/redesign/CardContainer';
import KpiContainer from 'components/redesign/KpiContainer';
import GridContainer from 'components/redesign/GridContainer';
import MixLineOverlapBarChart from 'components/MixLineOverlapBarChart';
import { useOverview } from 'hooks/overview-hook';
import { FilterContext } from 'components/FilterBar/FilterProvider';
import { constants, screenSizes } from '../../../../../constants/Constants';
import KpiDefinitionData from 'service/KpiDefinitionData';
import useCustomMediaQuery from 'utils/useCustomMediaQuery';
import {
  createAppPerformanceData,
  createDataList,
} from '../helper/appPerformance';
import { B2BOverviewStyles as Styled } from '../../style';
import { useMetricsCriteria } from 'hooks/useMetricsCriteria';
import IncidentCountView from './IncidentCountView';
import { incidentMonitoringScorecrad } from '../helper/incidentMonitoringScorecrad';
import { useApplicationPerformance } from 'hooks/application-performance-hook';

interface AppPerformanceHOCProps {
  slug?: string;
  title: string;
  isOverview?: boolean;
}

const AppPerformanceHOC: React.FC<AppPerformanceHOCProps> = ({
  slug,
  title,
  isOverview = false,
}) => {
  const defaultAssignmentGroup = { value: 9999, label: 'All' };
  const defaultFrequency = { value: 182, label: 'Monthly' };
  const { ytdCheckbox, selectedProduct, categories, selectedApplicationNames } =
    useContext(FilterContext);
  const isMobile = useCustomMediaQuery(screenSizes.mobile);
  const isTablet = useCustomMediaQuery(screenSizes.tablet);
  const { operationalMetricCriteria } = useMetricsCriteria();

  const {
    applicationPeformanceMetricsInOverviewData,
    getApplicationPeformanceMetricsInOverviewData,
    applicationPeformanceMetricsInOverviewLoading,
  } = useOverview();

  const { nrmAppPerfScoreCardData, getNRMAppPerformanceScoreCardData } =
    useApplicationPerformance();

  useEffect(() => {
    const applicationMetricCriteria = {
      ...operationalMetricCriteria,
      categories: categories,
      platforms: selectedApplicationNames,
    };
    const incidentMetricCriteria = {
      ...operationalMetricCriteria,
      categories: categories,
      applicationNames:
        selectedProduct?.productId === constants.products.PepsiConnect
          ? constants?.applicationPerformance?.assignedGroupNames
          : [],
      ...(selectedProduct?.productId === constants.products.Kazandirio && {
        assignmentGroupId: defaultAssignmentGroup?.value,
        period: defaultFrequency?.value,
      }),
    };

    getApplicationPeformanceMetricsInOverviewData({
      variables: {
        applicationMetricCriteria,
        incidentMetricCriteria,
      },
    });
  }, [
    getApplicationPeformanceMetricsInOverviewData,
    categories,
    selectedApplicationNames,
    ytdCheckbox,
    selectedProduct,
    operationalMetricCriteria,
  ]);

  useEffect(() => {
    const scoreCardParams = {
      ...operationalMetricCriteria,
    };
    getNRMAppPerformanceScoreCardData({
      variables: {
        applicationMetricCriteria: scoreCardParams,
      },
    });
  }, [getNRMAppPerformanceScoreCardData, operationalMetricCriteria]);

  const getMetricsData = () => {
    if (selectedProduct?.productId == constants?.products?.Kazandirio) {
      const apiData = {
        applicationPeformanceMetricsInOverviewData,
        nrmAppPerfScoreCardData,
      };
      return incidentMonitoringScorecrad(apiData, ytdCheckbox)?.metrics;
    } else {
      return createAppPerformanceData(
        applicationPeformanceMetricsInOverviewData,
        ytdCheckbox
      )?.metrics;
    }
  };

  const metrics = getMetricsData();

  return (
    <CardContainer
      slug={slug}
      title={title}
      showInfoIcon={true}
      dataDefinitions={{
        sections:
          KpiDefinitionData[selectedProduct?.productId]?.overview
            ?.applicationPerformance ?? [],
      }}
      isLoading={applicationPeformanceMetricsInOverviewLoading}
      isDraggable={isOverview ? false : true}
    >
      <GridContainer>
        <KpiContainer title={isOverview ? '' : 'Incident Monitoring'}>
          {selectedProduct?.productId === constants.products.Kazandirio ? (
            <>
              <IncidentCountView
                metrics={[metrics[0], metrics[1]]}
                columns={2}
              />
              <IncidentCountView
                metrics={[metrics[2], metrics[3], metrics[4]]}
              />
            </>
          ) : (
            <IncidentCountView metrics={metrics} />
          )}
        </KpiContainer>
        <KpiContainer title="Incident Trend">
          <Styled.CharContainerHeight>
            <MixLineOverlapBarChart
              data={createDataList(
                applicationPeformanceMetricsInOverviewData,
                isMobile,
                isTablet
              )}
              height={'230%'}
            />
          </Styled.CharContainerHeight>
        </KpiContainer>
      </GridContainer>
    </CardContainer>
  );
};

export default AppPerformanceHOC;
