import { startOfWeek, addDays, format } from 'date-fns';

export const getWeekRange = (date) => {
  // Get the start of the week (Tuesday-based)
  const start = startOfWeek(date, { weekStartsOn: 2 }); // Ensures week starts on Tuesday
  const end = addDays(start, 6); // Ends on Monday (6 days after Tuesday)

  // Convert to UTC: set start time to 00:00:00.000 and end time to 23:59:59.000
  const utcStart = new Date(
    Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0, 0)
  );
  const utcEnd = new Date(
    Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 0)
  );

  return {
    start: utcStart.toISOString(),
    end: utcEnd.toISOString(),
    formattedRange: `${format(utcStart, 'd MMM yyyy')} - ${format(end, 'd MMM yyyy')}`,
  };
};

const getLocalStorageKeyForWeek = (selectedProgram, selectedProduct) =>
  `weekFilter_${selectedProgram}_${selectedProduct}`;

export const saveSelectedWeekLocally = (
  selectedWeek,
  selectedProgram,
  selectedProduct
) => {
  const key = getLocalStorageKeyForWeek(selectedProgram, selectedProduct);
  localStorage.setItem(key, JSON.stringify(selectedWeek));
};

export const getSavedWeekFromLocalStorage = (
  selectedProgram,
  selectedProduct
) => {
  const key = getLocalStorageKeyForWeek(selectedProgram, selectedProduct);
  const storedWeek = localStorage.getItem(key);
  return storedWeek ? new Date(JSON.parse(storedWeek)) : null;
};

export const determineYearToUse = (selectedYear, minSelectableYear) => {
  if (minSelectableYear && minSelectableYear > selectedYear) {
    return minSelectableYear;
  }
  return selectedYear;
};
