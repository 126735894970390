import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Outlet } from 'react-router-dom';
import { useOkta } from 'hooks/okta-hooks';

const PrivateRoutes = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { authenticate } = useOkta();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      authenticate();
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return null;
  }

  return <Outlet />;
};

export default PrivateRoutes;
