import styled, { keyframes } from 'styled-components';
import { color } from 'theme/Color';

interface CustomCardLoaderContainerProps {
  mode: string;
}

// Keyframes for animation
const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -123;
  }
`;

// Base spinner and layout styles
const BaseLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .spinner-header {
    margin-left: 25px;
  }
  .spinner-svg {
    animation: ${rotate} 2s linear infinite;
    height: 25px;
    margin-right: 4px;
    width: 25px;
  }
  .svg-spinner-background {
    fill: none;
    stroke: #c0c0c0;
    stroke-linecap: round;
    stroke-width: 3px;
  }
  .svg-spinner-spin {
    animation: ${dash} 1.7s ease-in-out infinite;
    fill: none;
    stroke: #1976d2;
    stroke-linecap: round;
    stroke-width: 3px;
  }
`;

// CustomCardLoaderContainer with conditional background based on `mode` prop
export const CustomCardLoaderContainer = styled(
  BaseLoaderContainer
)<CustomCardLoaderContainerProps>`
  background: ${({ mode }) =>
    mode === 'light'
      ? `${color._FBFCFE} !important`
      : `${color._1d1d1d} !important`};
  padding: 18px;
  margin-top: 20px;
`;

// CustomLoaderContainer using the same BaseLoaderContainer, for flexibility
export const CustomLoaderContainer = styled(
  BaseLoaderContainer
)<CustomCardLoaderContainerProps>`
  // Additional or overriding styles can go here if needed
  padding: 18px;
  margin-top: 20px;
`;
