import {
  formatPercentageValue,
  formatNormalNumber,
  createKpiTileItem,
} from '../../../../utils/helper';

export default {
  type: 'KpiTile',
  handler: (data) => {
    const { activeUser, activeUsersPercentageDelta } =
      data.getUserAdoptionScorecard;

    return createKpiTileItem(
      'Active Users',
      formatNormalNumber(activeUser),
      Number(activeUsersPercentageDelta) !== 0
        ? {
            isPositiveValue: activeUsersPercentageDelta >= 0,
            text: formatPercentageValue(Math.abs(activeUsersPercentageDelta)),
            sideLabel: '',
            showArrow: true,
          }
        : null
    );
  },
};
