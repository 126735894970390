import {
  formatPercentageValue,
  createKpiTileItem,
  formatNormalNumber,
} from '../../../../utils/helper';

export default {
  type: 'KpiTile',
  handler: (data) => {
    const { accountsOnboarded, accountsOnboardedPercentageDelta } =
      data.getUserAdoptionScorecard;

    return createKpiTileItem(
      'Total Accounts Onboarded',
      formatNormalNumber(accountsOnboarded),
      Number(accountsOnboardedPercentageDelta) !== 0
        ? {
            isPositiveValue: accountsOnboardedPercentageDelta >= 0,
            text: formatPercentageValue(
              Math.abs(accountsOnboardedPercentageDelta)
            ),
            sideLabel: '',
            showArrow: true,
          }
        : null
    );
  },
};
